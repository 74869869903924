import React from "react";
import { observer } from "mobx-react";
import store from "../config/store";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Icon,
  Skeleton,
  Tooltip,
  Tag,
  notification,
  Modal,
  Spin
} from "antd";
import axios from "axios";
import moment from "moment";
import {
  Twitter,
  Facebook,
  Mail,
  Whatsapp,
  Linkedin
} from "react-social-sharing";
import analytics from "../config/analytics";
import { IntercomAPI } from "react-intercom";

const Notification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message
  });
};

class Dashboard extends React.Component {
  state = {
    loading: true,
    visible: false,
    share_message: null
  };

  componentDidMount = async () => {
    await store.fetchAllSurveys();

    this.setState({
      loading: false
    });

    analytics.track(analytics.events.DASHBOARD_VIEWED);
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    //get details
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  editSurvey = id => {
    this.props.history.push(`/edit_survey/${id}`);
  };

  sendReminders = () => {
    var checkToggle = window.confirm(
      'This will send an SMS message to everyone who begun to take your surveys but did not complete. Message content: "Hi! We noticed you did not complete the survey for the keyword: [keyword]. Please dial *718*788# and enter [keyword] to complete this survey!"  Proceed?'
    );
    if (checkToggle === true) {
      axios
        .post(`surveys/reminders/send/all`)
        .then(response => {
          Notification("success", "Success", "SMS Message sent successfully.");
        })
        .catch(error => {
          console.log("Error: ", error);
          Notification("error", "Error", "Error while sending SMS. " + error);
        });
    } else {
      //do nothing
    }
  };

  deleteSurvey = id => {
    var checkToggle = window.confirm(
      "Are you sure you want to delete this survey?"
    );
    if (checkToggle == true) {
      axios
        .post(`surveys/delete/${id}`)
        .then(response => {
          store.fetchAllSurveys();
          console.log("Response: ", response);
          Notification("success", "Success", "Survey deleted successfully");
        })
        .catch(error => {
          console.log("Error: ", error);
          Notification(
            "error",
            "Error",
            "Error while deleting survey." + error
          );
        });
    } else {
      //do nothing
    }
  };

  shareSurvey = (id, word) => {
    this.showModal();

    this.setState({
      share_message: `Hello, I would love to get your feedback on my survey. Please dial *718*788# and then type in ${word} to begin! You can do this with your MTN, Vodafone or Airteltigo number.  All responses are free! Created with https://ussdsurveys.com`
    });
  };

  viewSurvey = id => {
    // alert(id)
    this.props.history.push({
      pathname: `/surveys`,
      state: { selectedSurvey: id }
    });
  };

  renderSurveysList = () => {
    if (store.surveys.length === 0) {
      return (
        <Card style={{ width: "100%", height: "10%", marginTop: 16 }}>
          <div>
            <Row>
              <Col xs={24} sm={24} md={6} lg={6}>
                <br />
                <span style={{ fontSize: "1.8em", marginBottom: "10px" }}>
                  <b>No created surveys yet!</b>
                </span>
                <br />
                <span style={{ color: "gray" }}>
                  Create surveys and distribute them to collect information you
                  want
                </span>{" "}
                <br />
                <br />
              </Col>
              <Col xs={24} sm={24} md={2} lg={2}>
                <br />
                <br />
                <center>
                  <Icon style={{ fontSize: "2em" }} type="caret-right" />
                </center>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <p>
                  <Icon type="key" /> Step 1
                </p>
                <p style={{ fontWeight: 600, fontSize: "1.8em" }}>
                  Register A Keyword
                </p>
                <span style={{ color: "gray" }}>
                  Keywords will need to be entered by your participants.
                  <br />
                  The customer dials the USSD Code (e.g. *718*788#)
                  <br /> then enters the keyword to take your survey
                </span>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <p>
                  <Icon type="file-unknown" /> Step 2
                </p>
                <p style={{ fontWeight: 600, fontSize: "1.8em" }}>
                  Create a survey
                  <br />
                </p>
                <span style={{ color: "gray" }}>
                  Create your survey using our survey creation tool
                  <br /> ontop of the registered keyword and your survey will
                  <br />
                  be ready to distribute instantly!
                </span>
              </Col>
            </Row>
          </div>
        </Card>
      );
    } else {
      return store.surveys.map(survey => {
        let {
          title,
          description,
          engagement_count,
          created_at,
          is_active,
          question_counts,
          textword
        } = survey;
        engagement_count = engagement_count > 0 ? engagement_count : "-";
        question_counts = question_counts > 0 ? question_counts : "-";
        created_at = moment(created_at).format("DD/MM/YYYY");
        return (
          <Card
            key={survey.id}
            style={{ width: "100%", height: "10%", marginTop: 16 }}
            actions={[
              <Tooltip title="Edit">
                <Icon
                  onClick={() => this.editSurvey(survey.id)}
                  style={{ color: "#15A35E" }}
                  type="edit"
                />
              </Tooltip>,
              <Tooltip title="Delete">
                <Icon
                  onClick={() => this.deleteSurvey(survey.id)}
                  style={{ color: "#A21B1B" }}
                  type="delete"
                />
              </Tooltip>,
              <Tooltip title="Share">
                <Icon
                  onClick={() => this.shareSurvey(survey.id, textword.word)}
                  style={{ color: "#3F0404" }}
                  type="share-alt"
                />
              </Tooltip>,
              <Tooltip title="View">
                <Icon
                  onClick={() => this.viewSurvey(survey.id)}
                  style={{ color: "blue" }}
                  type="eye"
                />
              </Tooltip>
            ]}
          >
            <div>
              <Row>
                <Col xs={24} sm={24} md={16} lg={16}>
                  {is_active ? (
                    <Tag color="#53AE61">Active</Tag>
                  ) : (
                    <Tag color="red">Inactive</Tag>
                  )}
                  <br />
                  <span style={{ fontSize: "1.8em", marginBottom: "10px" }}>
                    <b>{title}</b>
                  </span>
                  <br />
                  <span style={{ color: "gray" }}>{description}</span> <br />
                  <br />
                  <span style={{ color: "gray", fontWeight: "600" }}>
                    Created: {created_at}
                  </span>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <p>Questions</p>
                  <p style={{ fontWeight: 600, fontSize: "1.8em" }}>
                    {question_counts}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <p>Engagements</p>
                  <p style={{ fontWeight: 600, fontSize: "1.8em" }}>
                    {engagement_count}
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        );
      });
    }
  };

  render() {
    const { loading, share_message } = this.state;

    console.log("Surveys length: ", store.surveys.length);
    return (
      <div>
        <div>
          <Modal
            title="Share Your Survey"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[]}
          >
            <div>
              <p>{share_message}</p>
              <Twitter link={share_message} />
              <Mail link={share_message} />
              <Whatsapp link={share_message} />
            </div>
          </Modal>
        </div>

        <div style={{ marginTop: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>
            {" "}
            Quick Actions
          </div>
          <div
            style={{
              border: "0.5px solid #E9E9E9",
              borderRadius: "2px",
              padding: "2%"
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Link to="/new_keyword">
                  <Card
                    bordered={true}
                    hoverable={true}
                    style={{ backgroundColor: "#E8E8F8" }}
                  >
                    <Row>
                      <Col>
                        <Icon
                          style={{ fontSize: "3em", color: "#907FF0" }}
                          type="plus"
                          theme="outlined"
                        />
                      </Col>
                      <Col>
                        <p style={{ fontSize: "1.5em", fontWeight: 600 }}>
                          Register Keyword
                        </p>
                        <span style={{ fontSize: "1em", color: "grey" }}>
                          Register a new keyword to deploy your new survey
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Link to="/new_survey">
                  <Card
                    bordered={true}
                    hoverable={true}
                    style={{ backgroundColor: "#F8E8E8" }}
                  >
                    <Row>
                      <Col>
                        <Icon
                          style={{ fontSize: "3em", color: "#F07F7F" }}
                          type="plus"
                          theme="outlined"
                        />
                      </Col>
                      <Col>
                        <p style={{ fontSize: "1.5em", fontWeight: 600 }}>
                          Create Survey
                        </p>
                        <span style={{ fontSize: "1em", color: "grey" }}>
                          Create a new survey using your registered keyword
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Card
                  bordered={true}
                  hoverable={true}
                  style={{ backgroundColor: "#F4F4D1" }}
                  onClick={this.sendReminders}
                >
                  <Row>
                    <Col>
                      <Icon
                        style={{ fontSize: "3em", color: "#BBC02E" }}
                        type="bell"
                        theme="outlined"
                      />
                    </Col>
                    <Col>
                      <p style={{ fontSize: "1.5em", fontWeight: 600 }}>
                        Send Reminders
                      </p>
                      <span style={{ fontSize: "1em", color: "grey" }}>
                        Send an SMS to everyone that didn't finish completing
                        your surveys
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/*<Col span={8}>
                <Link to="/import_contacts">
                  <Card
                    bordered={true}
                    hoverable={true}
                    style={{ backgroundColor: "#E8F8E8" }}
                  >
                    <Row>
                      <Col span={4}>
                        <Icon
                          style={{ fontSize: "3em", color: "#7FF09B" }}
                          type="plus"
                          theme="outlined"
                        />
                      </Col>
                      <Col>
                        <p style={{ fontSize: "1.5em", fontWeight: 600 }}>
                          Import Contacts
                        </p>
                        <span style={{ fontSize: "1em", color: "grey" }}>
                          Add new contacts you may have from pre-existing
                          sources
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Link>
          </Col>*/}
            </Row>
          </div>
        </div>

        <div style={{ marginTop: "5%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}> My Surveys </div>
          <div>
            {this.state.loading ? (
              <center>
                <br />
                <Spin />
              </center>
            ) : (
              <Row>
                <Col>{this.renderSurveysList()}</Col>
              </Row>
            )}
          </div>
        </div>

        {/* <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Hello {store.displayName}</p>
            <a
              className="App-link"
              href="http://ussdsurveys.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Landing Page
          </a>
          </header>
        </div> */}
      </div>
    );
  }
}

export default observer(Dashboard);
