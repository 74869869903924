import axios from "axios";
import store from "./store";
import analytics from "./analytics";
export const isSignedIn = async () => {
  const token = await localStorage.getItem("accessToken");
  console.log("Checking isSigned In: ", token);
  axios.defaults.headers["Authorization"] = token;

  return token !== null;
};

export const onSignOut = async reason => {
  axios.defaults.headers["Authorization"] = null;
  await localStorage.removeItem("accessToken");

  analytics.amplitude.setUserId(null);
  store.clear();
};
