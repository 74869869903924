import React from "react";
import { Form, Icon, Input, Button, Card, Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import store from "../config/store";
import { handleError, errorMessage } from "../util/helpers";
import analytics from "../config/analytics";
import Intercom from "react-intercom";
const { Option } = Select;

class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    loading: false,
    code: ""
  };

  componentDidMount = () => {
    const search = window.location.search;
    const code = search.split("invite_code=")[1];
    if (code) {
      this.setState({
        code
      });
    }

    analytics.track(analytics.events.REGISTER_VIEWED, { Code: code });
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords do not match! Please make sure they match!");
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleSubmit = e => {
    analytics.track(analytics.events.REGISTER_CLICKED);
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const { email, password, prefix, phone, first, last } = values;
        const formattedPhone = `${prefix}${phone}`;
        const { code } = this.state;

        try {
          const response = await axios.post("/accounts/", {
            first,
            last,
            email,
            phone: formattedPhone,
            password,
            passwordConfirmation: password,
            code
          });
          const account = response.data.account;
          console.log("@@ Newly Created Account: ", account);
          this.completeAuthentication(account.token);
          store.setCurrentUser(account.first_name, account.last_name);
          analytics.track(analytics.events.REGISTER_SUCCESS, {
            "Account Id": account.id
          });
        } catch (error) {
          const message = errorMessage(error);
          handleError(error);
          analytics.track(analytics.events.REGISTER_FAILED, { Error: message });
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
        analytics.track(analytics.events.REGISTER_FAILED, {
          Message: "Validation Errors",
          Error: err
        });
      }
    });
  };

  completeAuthentication = token => {
    localStorage.setItem("accessToken", token);
    axios.defaults.headers["Authorization"] = token;

    this.setState({
      loading: false
    });

    // TODO: Verify the phone number first?
    this.props.history.push("/dashboard");
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "+233"
    })(
      <Select style={{ width: 80 }}>
        <Option value="+233">+233</Option>
      </Select>
    );

    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "#f2f4f8",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Intercom appID="mm2qwj9g" />

        <Card
          title={"Register for USSD Surveys"}
          style={{ minWidth: "450px", maxWidth: "500px" }}
        >
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            style={{ minWidth: "450px", maxWidth: "500px" }}
          >
            <Form.Item label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!"
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!"
                  },
                  {
                    validator: this.validateToNextPassword
                  }
                ]
              })(<Input.Password />)}
            </Form.Item>

            <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!"
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </Form.Item>

            <Form.Item label="Phone Number">
              {getFieldDecorator("phone", {
                rules: [
                  { required: true, message: "Please input your phone number!" }
                ]
              })(
                <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
              )}
            </Form.Item>

            <Form.Item label="First Name">
              {getFieldDecorator("first", {
                rules: [
                  {
                    required: true,
                    message: "Please input your first name!",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Last Name">
              {getFieldDecorator("last", {
                rules: [
                  {
                    required: true,
                    message: "Please input your last name!",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                style={{ width: "150%", textAlign: "center" }}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
          <div>
            <a href="https://ussdsurveys.com/login">Already have an account? Click here to log in</a>
          </div>
        </Card>
      </div>
    );
  }
}

export default Form.create({ name: "register" })(RegistrationForm);

/*
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Please input your email!" }]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                />
              )}
            </Form.Item>
            */

/*
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            */
