import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './App';
import routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

// Set up Axios
console.log("Set base URL in axios");
// axios.defaults.baseURL = "http://localhost:3002/v1";
axios.defaults.baseURL = "https://africa-texting.herokuapp.com/v1";

// Load whatever tokens we need before showing routes?
// Or what?
// Do we need to have some kind of initial loading app?

ReactDOM.render(routes, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
