import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Row,
  List,
  Icon,
  Input,
  Tooltip,
  Button,
  Tag,
  Checkbox,
  notification,
  Popover,
  Modal,
  Radio,
  Switch
} from "antd";
import "../index.css";
import store from "../config/store";
import { async } from "q";
import { Link } from "react-router-dom";
import { IntercomAPI } from "react-intercom";
import { observer } from "mobx-react";
import analytics from "../config/analytics";


class ReferralTerms extends React.Component {
  state = {
    firstName: ""
  };

  componentWillMount = () => {
    analytics.track(analytics.events.PRICING_VIEWED);
  };

  handlePremiumClicked = () => {
    IntercomAPI(
      "showNewMessage",
      "Hello there! I would like to upgrade my account to a PREMIUM ACCOUNT. Please assist me on the next steps to do so. Thank you!"
    );
    IntercomAPI("trackEvent", "Premium - Clicked");
    analytics.track(analytics.events.PRICING_PREMIUM_CLICKED);
  };

  render() {
    return (
      <div>
        <div>
          <br /><h1>USSDSurveys Referral Program Terms and Conditions</h1><br />

          USSDSurveys ("we," "us" the "Company") offers you the opportunity to earn rewards by referring friends to try our Premium USSDSurveys Service. Your participation in the USSDSurveys Referral Program ("USSDSurveys Referral Program" or "Program") can earn you credits to use in conjunction with USSDSurveys Services, and may also get you additional rewards. You can also redeem this credit as cash. We reserve the right to modify or terminate the Program at any time for any reason. The Program is administered by USSDSurveys, which may, outsource certain elements of administration to third parties (collectively "Administrator").

These terms ("Terms") apply to a user's participation in the Program. By participating in the Program, users agree to use the Program as outlined herein, and consistent with any other terms we may apply to the Program. If you do not agree to these Terms in their entirety, then you cannot register and participate in the Program. Users also cannot where in so doing, they would violate any applicable law or regulations. <br />

          <br /><h3>Eligibility</h3><br />

          This Program is void where such referral programs are prohibited. Users who refer others to the program are "Referrers"; those who are referred are "Referred Customers." Referrers are may be eligible to receive "Credits" for every qualified referral. Referrers must speak and read English, be legally able to participate in the Program, and must be 1) at least the age of majority where they reside, 2) have an existing, valid USSDSurveys user account, and 3) are otherwise in good standing. When registering for the Program, Referrers must use the same email address that is registered with their existing USSDSurveys user account. USSDSurveys reserves the right to determine if a Referrer's USSDSurveys user account is valid based on criteria that includes, but is not limited to, USSDSurveys account activity and ownership, incorporation status, and business affiliation of the user's registered USSDSurveys email address domain. Participation in the Program represents an ongoing relationship with you, for privacy purposes. So long as you remain in the Program, and you have unused Credits, you will be considered an active member of the Program and of the Twilio Community. Please see USSDSurveys’ privacy policy for further details on your privacy rights. <br />


          <br /><h3>How It Works</h3><br />

          To participate, once you have created your USSDSurveys account, visit www. ussdsurveys.com/referrals and follow the on-screen instructions to start referring. You will be provided a link which you can share with your friends and colleagues as much as you want. <br />

          When a Referred Customers joins USSDSurveys from your referral link, you’ll earn 30% of their first initial payment, then 15% on any other recurring payment as well, net of any discounts applied by the customer. Also, we shall give back GHS 0.01 ie 1 GHp, on all USSD Sessions and SMS applied your referred customers. If Referred Customers signs up for a free account, no worries, we still give back GHS 0.01 ie 1 GHp, on all USSD Sessions and SMS we apply to that Referred Customers account. <br />

          <br /><h3>Special Promotions</h3><br />

          From time to time, Referrers may be offered special promotions ("Special Promotion"), or special links ("Personal Bonus Link") to use. These Special Promotions may be offered to some, but not all Users, depending upon your Credit status, your geographic location, or for other reasons. Such Special Promotions are for a limited time and will give Referrers (and in some cases, Referred Customers) additional perks and rewards. Additional terms, including expiration dates for any Special Promotion will be provided with the Special Promotion. During Special Promotions, Referrers may use either their Personal Link, or their Personal Bonus Link, and will receive Credit based upon the specific link used. Sorry, during any Special Promotion, the Referrer will only receive the identified credit outlined Special Promotion only if the Referred User completes the task prior to the end date of the Special Promotion period. <br />


          <br /><h3>Conditions for Receiving Credit</h3><br />

          Credit will be awarded for Qualified Referrals who meet the following conditions: <br />

          1.	The Referred Customer must use the Personal Link from a Referrer in good standing with USSDSurveys. <br />
          2.	If a Referred Customer receives more than one Personal Link, USSDSurveys will provide the Credit to the person whose Personal Link is used to complete the USSDSurveys registration process regardless of when the Personal Links were sent. If a Referred Customer registers for the Premium Service using any other method, the registration will not count as a Qualified Referral and Referrer will not earn Credit; <br />
          3.	The Referred Customer may not combine the link with any other monetary offer. <br />
          4.	The Referred Customer may not receive a credit back for the Premium Service for up to 1 month. <br />
          5.	The Referred Customer must not be registered with the Premium Service, and was not previously registered with the Service under any email address or alias for at least 12 months; <br />
          6.	The Referred Customer must be eligible to create a USSDSurveys account and otherwise be qualified. <br />
          7.	The Referred Customer must register for the Upgraded Service using a valid and current email address that has not been used to register for the Premium Service within the last 12 months. <br />



          <br /><h3>How Credits Work</h3><br />

          Referrers are allowed up to 100 unique Qualified Referrals. <br />

          Credit are subject to verification and will generally be awarded within 30 days of verification. USSDSurveys entities may withhold a Credit if it reasonably believes additional verification is required. USSDSurveys may also withhold or invalidate any potential it deems fraudulent, suspect, or in violation of these Terms. If USSDSurveys in its sole discretion believes awarding a credit or verifying and approving a transaction will impose liability on USSDSurveys, its subsidiaries, affiliates or any of their respective officers, directors, employees, representatives and agents. <br />

          All USSDSurveys Entities' decisions are final and binding, except where prohibited, including decisions as to whether a Qualified Referral, or Credit is valid, when and if to terminate the Program, and whether, if at all, to change the program. Any changes to the program will be sent via email to registered Referrer's and, except where prohibited, will become effective as of the date the email is sent. If a Referrer has referrals pending qualification at the time that updates are sent, those pending referrals shall be validated and Credits given under the terms that were valid at the time the Referred Customer signed up for the Premium Service. <br />

          <br /><h3>Use of Credits</h3><br />

          Credits may only be used for qualified USSDSurveys services, which can change at any time. They may not be traded. Credits are not transferable, salable, or auctionable. If the Program is terminated by USSDSurveys, Referrers will have 6 months to use any Credits before they are forfeited. If a Referrer's account is cancelled for any reason, unredeemed Credits are forfeited immediately. If Referrer's account is suspended for any reason, upon resumption of account privileges. <br />

          <br /><h3>Redeem Credits in Cash</h3><br />
          Credits received via the referral program have monetary value, and hence can be redeemed for cash. Cash will only be redeemed and paid out via a ChipperCash app. In order to redeem credits, Referrers must have an active and verified account with ChipperCash, the app can be downloaded at www.chippercash.com. A request to redeem your credit must be put in via email to team@ussdsurveys.com. A request to redeem can also sent via our chat support platform on our website. <br />


          <br /><h3>Referrer's Code of Conduct</h3><br />

          Referrer's agree that they will not violate any of these Terms, or otherwise engage in activity that could be considered harassment toward other users. Users agree not to use the Program to: <br />

          •	Violate the intellectual property rights of USSDSurveys<br />
          •	Spam or otherwise create bulk distributions of the Personal Link or the Personal Bonus Link that is inappropriate<br />
          •	Collect or attempting to collect personal data about users or potential Referred Customers<br />
          •	Engage in any actions that are designed to disrupt or undermine the Program<br />
          •	Make attempts to gain unauthorized access to the software or the Program for any reason<br />
          •	Transmit files that contain bots, viruses, works, Trojan horses, or any other file that could contaminate or otherwise destroy USSDSurveys intellectual property or stop the function of the USSDSurveys services<br />
          •	Engage in illegal or unsportsmanlike activities<br />
          •	Engage in behavior designed to annoy or harass others<br />
          •	Engage in actions that disparage or malign or call into question the reputation of USSDSurveys, in USSDSurveys’ sole discretion<br />


          <br /><h3>Reservations of Rights</h3><br />

          We reserve the right to modify or amend at any time these Terms and the methods through which Credit are earned. We reserve the right to disqualify any User at any time from participation in the Program if he/she does not comply with any of these Terms. USSDSurveys Entities' failure to enforce any term of these Terms shall not constitute a waiver of that provision. <br />


        </div>
      </div>
    );
  }
}

export default observer(ReferralTerms);
