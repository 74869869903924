export const handleError = error => {
  const message = errorMessage(error);
  window.alert(message);
};

export const errorMessage = error => {
  let message = "";
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    let custom = customErrorMessage(error);
    if (custom) {
      message = custom;
    } else {
      message = error.response.data.error.message;
    }
  } else if (error.message) {
    message = error.message;
  }
  return message;
};

// Get the error code for that error
export const errorCode = error => {
  let code = "0000";
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.code
  ) {
    code = error.response.data.error.code;
  }
  return code;
};

// This is how you can customize the error message a little bit more depending on the code
const customErrorMessage = error => {
  const code = error.response.data.error.code;

  // This is how you would do a customErrorMessage. Leaving for reference.

  /*
  switch (code) {
    case "5001":
      return "Insufficient balance \n\nIt looks like your Chipper balance is not enough to complete this payment. Please add some money to your Chipper wallet by cashing in from your linked Mobile Money account";
    case "4008":
      return "This action cannot be completed because your account is temporarily frozen\n\nPlease reach out to support@chippercash.com for assistance";
    case "4003":
      return "The Chipper PIN was not correct. Please try again";
    case "9003":
      return "Looks like you have already redeemed a referral code from using a referral link";
    default:
      break;
  }
  */
};
