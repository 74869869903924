import { observable, computed, action, decorate } from "mobx";
import axios from "axios";
import analytics from "./analytics";

class Store {
  // Observables
  first = "";
  last = "";
  accountId = "";
  phone = "";
  email = "";
  isPremium = false;
  isAdmin = false;
  senderId = "";
  balance = 0;

  textwords = [];
  surveys = [];

  // Computed
  get displayName() {
    return `${this.first} ${this.last}`;
  }

  // Actions
  setCurrentUser = async (first, last, email) => {
    this.first = first;
    this.last = last;
    this.email = email;
  };

  fetchCurrentUser = async () => {
    const response = await axios.get("/accounts");
    const { account, textwords, balance } = response.data;

    this.first = account.first_name;
    this.last = account.last_name;
    this.email = account.email;
    this.accountId = account.id;
    this.phone = account.phone;
    this.isPremium = account.is_premium;
    this.isAdmin = account.is_admin;

    let senderId = "";
    if (account.sender_id) {
      senderId = account.sender_id;
    } else {
      senderId = "USSDSURVEYS";
    }

    this.senderId = senderId;

    this.balance = balance;
    this.textwords = textwords;

    // Save these on Amplitude.
    analytics.amplitude.setUserId(this.accountId);
    analytics.setUserProperty(
      analytics.userProperties.DISPLAY_NAME,
      `${this.first} ${this.last}`
    );

    analytics.setUserProperty(
      analytics.userProperties.DISPLAY_NAME,
      `${this.first} ${this.last}`
    );

    analytics.setUserProperty(
      analytics.userProperties.IS_PREMIUM,
      this.isPremium
    );

    analytics.setUserProperty(
      analytics.userProperties.NUM_KEYWORDS,
      this.textwords.length
    );
  };

  fetchAllSurveys = async () => {
    const response = await axios.get("/surveys");
    console.log("All Surveys: ", response.data);
    this.surveys = response.data.surveys;
  };

  clear = () => {
    this.first = "";
    this.last = "";
    this.email = "";
    this.accountId = "";
    this.phone = "";
    this.balance = 0;

    this.textwords = [];
    this.surveys = [];
  };
}

decorate(Store, {
  // Observables
  first: observable,
  last: observable,
  accountId: observable,
  phone: observable,
  email: observable,
  isPremium: observable,
  isAdmin: observable,
  senderId: observable,

  balance: observable,
  textwords: observable,
  surveys: observable,

  // Computed
  displayName: computed,

  // Actions
  setCurrentUser: action,
  fetchAllSurveys: action
});

const store = new Store();
export default store;
