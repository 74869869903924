import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Row,
  List,
  Icon,
  Input,
  Tooltip,
  Button,
  Tag,
  Checkbox,
  notification,
  Popover,
  Modal,
  Radio,
  Switch
} from "antd";
import "../index.css";
import store from "../config/store";
import { async } from "q";
import { Link } from "react-router-dom";
import { IntercomAPI } from "react-intercom";
import { observer } from "mobx-react";
import analytics from "../config/analytics";


class PrivacyPolicy extends React.Component {
  state = {
    firstName: ""
  };

  componentWillMount = () => {
    analytics.track(analytics.events.PRICING_VIEWED);
  };

  handlePremiumClicked = () => {
    IntercomAPI(
      "showNewMessage",
      "Hello there! I would like to upgrade my account to a PREMIUM ACCOUNT. Please assist me on the next steps to do so. Thank you!"
    );
    IntercomAPI("trackEvent", "Premium - Clicked");
    analytics.track(analytics.events.PRICING_PREMIUM_CLICKED);
  };

  render() {
    return (
      <div>
        <div>
          <br /><h1>USSDSURVEYS PRIVACY STATEMENT</h1><br />

          USSDSurveys is a tool that enables one to create surveys and send to anyone in Ghana via USSD. These people will not need an internet connection whatsoever and can take your survey anytime.

          Here at USSDSurveys, we do our best to be thoughtful and deal in an honest, direct and transparent way when it comes to your data.


          <br /><br /><h3>Introduction (Types of data). </h3><br />
          USSDSurveys processes two broad categories of personal information when you use our products and services:

          •	Your personal information as a customer (or potential customer) of USSDSurveys - information that we refer to as Customer Account Data, and
          •	The personal information of your end users’ who use or interact with your surveys that you’ve created on USSDSurveys platform - this category contains your Customer Usage Data (e.g., survey responses, phone numbers). .

          USSDSurveys processes these categories of personal information differently because the direct relationship we have with you, our customer, is different than the indirect relationship we have with your end users.




<br /><br /><h3>How USSDSurveys processes your personal information</h3><br />
          We, USSDSurveys, collect and process your personal information:
          When you visit our USSDSurveys public-facing website like ussdsurveys.com
          When you contact USSDSurveys’ Customer Support or Sales Teams; and
          When you sign up for a USSDSurveys account and use our products and services.

          We call this personal information Customer Account Data.
          Broadly speaking, we use Customer Account Data to further our legitimate interests to:
          understand who our customers and potential customers are and their interests in USSDSurveys’ product and services,
          manage our relationship with you and other customers,
          carry out core business operations such as operational & accounting
          help detect, prevent, or investigate security incidents, fraud and other abuse and/or misuse of our products and services.


<br /><br /><h3>What customer account data USSD Surveys processes when you communicate with our customer support or sales teams and why? </h3><br />
          You may share personal information, like your contact information, with a member of our Customer Support or Sales Teams when you communicate with them. We keep a record of this interaction.

          If you contact our Customer Support or Sales Teams, those teams keep a record of that communication, including your contact details and other information you share during the course of the communication. We store this information to help us keep track of the inquiries we receive from you and from customers generally so we can improve our products and services and provide training to team members. This information also helps our teams manage our ongoing relationships with our customers. Because we store a record of these communications, please be thoughtful about what information you share with our Customer Support or Sales Teams. While we will take appropriate measures to protect any sensitive information you share with us, it is best to avoid sharing any personal or other sensitive information in these communications not necessary for these teams to assist you.



<br /><br /><h3>How Long We Store Your Customer Account Data</h3><br />
          USSDSurveys will store your Customer Account Data as long as needed to provide you with our services and to operate our business. If you ask USSDSurveys to delete specific personal information from your Customer Account Data, we will honor this request unless deleting that information prevents us from carrying out necessary business functions, like billing for our services, calculating taxes, or conducting required audits.



<br /><h3>How USSDSurveys Processes Your End Users’ Personal Information</h3><br />
          Your end users’ personal information typically shows up on USSDSurveys’ platform in the following way:

          •	Your end users’ personal information that is their contact number may show up in “Survey Responses,” which provides a details breakdown of you end users survey responses to the survey they take.


          When USSDSurveys processes Customer Content, we generally act as a processor. When we process Customer Usage Data, we act as a processor in many respects. Such data is used to create graphical reports.

          We use Customer Usage Data to provide services to you and to carry out necessary functions of our business. We do not sell your end users’ personal information and we do not share your end users’ information with third parties for those third parties’ own business interests.

<br /><br /><h3>Changes to Our Privacy Statement</h3><br />
          We may change our Privacy Statement from time to time. If we make changes we’ll revise the “Effective” date at the top of this statement, and we may provide additional notice such as on the USSDSurveys website homepage, account portal sign-in page, or via the email address we have on file for you. We will comply with applicable law with respect to any changes we make to this notice and seek your consent to any material.

        </div>
      </div>
    );
  }
}

export default observer(PrivacyPolicy);
