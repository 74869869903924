import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Card, Col, Row, Icon, Input, Tooltip, Button, Collapse, notification, Select, Modal, Radio, Switch, Avatar, Tag, Popover } from 'antd';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Link } from 'react-router-dom'
import '../index.css';
import surveycreated from '../assets/images/surveycreated.png'
import Grid from 'antd/lib/card/Grid';
import store from '../config/store'
import { observer } from "mobx-react"
import analytics from '../config/analytics';
import { errorMessage } from '../util/helpers';

const successNotificationWithIcon = (type) => {
    notification[type]({
        message: 'Great!',
        description:
            `Survey created successfully.`,
    });
};

const failNotificationWithIcon = (type) => {
    notification[type]({
        message: 'Sorry!',
        description:
            `Multiple choice questions should have possible responses.`,
    });
};

class CreateSurvey extends React.Component {
    state = {
        mode: 'NEW',
        visible: false,
        type: false,
        textwords: [],
        surveyName: null,
        surveyDescription: null,
        surveyKeyword: null,
        surveyActive: true,
        keywordHasActiveSurvey: false,
        attributeForm: [],
        questionSet: [],
        questionSetCount: 0,
        selectedTextword: null,
        frequency: 'once',
        smsCompletionMessage: 'Thank you for taking this survey. You too can create a really quick USSD Survey in 2 minutes. Visit https://ussdsurveys.com to create your free survey',
        ussdCompletionAlert: 'Thank you for taking this survey!',

        setAnswers: 0,
        multiple_choice_question: null,
        open_ended_question: null,
        editingQuestionIndex: null,

        editingQuestionId: null,


        answers: [null, null, null, null, null],
        answerOne: null,
        answerTwo: null,
        answerThree: null,
        answerFour: null,
        answerFive: null,
        answerSix: null,
        multipleChoiceCheck: true,
        openEndedCheck: false,
        existingAnswers: null,
        loading: false,
    };

    componentDidMount = async () => {
        this.fetchTextwords()

        // Check if we're in edit mode.
        if (this.props.editSurveyId) {
            const surveyId = this.props.editSurveyId
            // Fetch the details fro this survey, and populate all the fields 
            // We're now in edit mode.

            try {
                const response = await axios.get(`/surveys/${surveyId}`)
                const { survey, questions } = response.data

                const { textword } = survey

                //  'multiple_choice_answers' to 'answers'
                questions.forEach(question => {
                    question['answers'] = question['multiple_choice_answers']
                })

                this.setState({
                    surveyName: survey.title,
                    surveyDescription: survey.description,

                    mode: 'EDIT',

                    questionSet: questions, 
                    questionSetCount: questions.length,

                    surveyKeyword: textword.id,
                    keywordHasActiveSurvey:
                    textword.has_active_survey, 
                    surveyActive: survey.is_active, 
                    selectedTextword: textword.word,

                    frequency: survey.submission_frequency,
                    smsCompletionMessage: survey.sms_completion_message,
                    ussdCompletionAlert: survey.ussd_completion_alert,
                    
                })
            } catch (error) {
                
                this.setState({
                    mode: 'EMPTY'
                })

                const message = errorMessage(error)
                notification['error']({
                    message: 'Yikes!',
                    description: message,
                });
            }
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ questionSet }) => ({
            questionSet: arrayMove(questionSet, oldIndex, newIndex),
        }));

        analytics.track(analytics.events.CREATE_SURVEY_REORDERED, {
            From: oldIndex,
            To: newIndex
        })
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showAddModal = () => {
        this.setState({
            visible: true,
            open_ended_question: "",
            multiple_choice_question: "",

            editingQuestionId: null,

            answerOne: null,
            answerTwo: null,
            answerThree: null,
            answerFour: null,
            answerFive: null,
            answerSix: null,
        });

        analytics.track(analytics.events.CREATE_SURVEY_ADD_QUESTION_CLICKED)
    };

    dismissModal = () => {
        this.setState({
            visible: false,
            editingQuestionIndex: null,
        });
    }

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
            editingQuestionIndex: null,
        });
    };

    handleCancel = e => {
        console.log(this.state.open_ended_question)
        console.log(this.state.multiple_choice_question)
        console.log(this.state.answerOne)
        console.log(this.state.answerTwo)
        console.log(this.state.answerThree)
        console.log(this.state.answerFour)
        console.log(this.state.answerFive)
        console.log(this.state.answerSix)

        if (
            this.state.open_ended_question === "" &&
            this.state.multiple_choice_question === "" &&
            (this.state.answerOne === null || this.state.answerOne === "") &&
            (this.state.answerTwo === null || this.state.answerTwo === "") &&
            (this.state.answerThree === null || this.state.answerThree === "") &&
            (this.state.answerFour === null || this.state.answerFour === "") &&
            (this.state.answerFive === null || this.state.answerFive === "") &&
            (this.state.answerSix === null || this.state.answerSix === "")
        ) {
            this.setState({
                visible: false,
                editingQuestionIndex: null,
            });

            analytics.track(analytics.events.CREATE_SURVEY_QUESTION_MODAL_DISMISSED, { 'Editing': false })

        } else {

            var checkToggle = window.confirm("Are you sure want to drop this question. You will lose the information you have typed. Continue?");
            if (checkToggle === true) {
                this.setState({
                    visible: false,
                    editingQuestionIndex: null,
                });
                analytics.track(analytics.events.CREATE_SURVEY_QUESTION_MODAL_DISMISSED, { 'Editing': true })
            } else {
                this.setState({
                    visible: true,
                    editingQuestionIndex: null,
                });
            }
        }
    };

    fetchTextwords = () => {
        axios
            .get("/accounts")
            .then(response => {
                const textwords = response.data.textwords.map(word => {
                    return {
                        key: word.id,
                        word: word.word,
                        hasActiveSurvey: word.has_active_survey
                    };
                });
                this.setState({
                    textwords,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    toggleSurveyActivity = (checked) => {
        this.setState({
            surveyActive: checked
        })
        analytics.track(analytics.events.CREATE_SURVEY_TOGGLE_ACTIVE, { Active: checked })
    }

    onQuestionType = event => {
        var value = event.target.value
        if (value === "multiple_choice") {
            this.setState({ type: false, multipleChoiceCheck: true, openEndedCheck: false, });
            analytics.track(analytics.events.CREATE_SURVEY_MULTI_CHOICE_TOGGLED)
        }
        if (value === "open_ended") {
            this.setState({ type: true, multipleChoiceCheck: false, openEndedCheck: true, });
            analytics.track(analytics.events.CREATE_SURVEY_OPEN_TOGGLED)
        }
    };

    addNewField = () => {
        // this method adds label and input field to the state array 
        var answersCount = this.state.setAnswers;

        if (answersCount < 5) {
            var array = this.state.attributeForm;
            array.push(
                <div key={answersCount}>
                    <div>
                        <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer" + answersCount}><b>{answersCount + 1}.</b> </label>
                        <Input id={"answer" + answersCount} name={"answer" + answersCount} style={{ width: "85%", backgroundColor: '#eee' }} />
                        {/* <Icon style={{ color: '#A21B1B', fontSize: '12px', padding: '0px 0px 0px 10px' }} onClick={() => { answersCount--; this.setState({ setAnswers: answersCount }) }} type="delete" theme="filled" /> */}
                        <br /><br />
                    </div>
                </div>
            );

            this.setState({
                attributeForm: array
            });
        }

        if (answersCount !== 5) {
            answersCount++;
        }

        console.log(answersCount)
        this.setState({
            setAnswers: answersCount
        })
    }

    appendInput = () => {
        var answersCount = this.state.setAnswers;

        if (answersCount < 5) {
            var newInput = `input-${this.state.inputs.length}`;
            this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
        }
        if (answersCount !== 5) {
            answersCount++;
        }

        console.log(answersCount)
        this.setState({
            setAnswers: answersCount
        })
    }

    removeInput = () => {
        // var inputArray = this.state.inputs
        // var popper = inputArray.pop();
        // console.log(popper)
        // this.setState({ inputs: popper })

        this.setState(prevState => ({ inputs: prevState.inputs.pop() }));
    }

    answer = (number, event) => {
        alert("this")
        console.log(number)
        console.log(event)
    }

    addExistingField = (answer) => {
        // This method adds label and input field to the state array 
        console.log(answer)
        var answersCount = this.state.setAnswers;

        if (answersCount < 5) {
            var array = this.state.attributeForm;
            array.push(
                <div key={answersCount}>
                    <div>
                        <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer" + answersCount}><b>{answersCount + 1}.</b> </label>
                        <Input id={"answer" + answersCount} value={answer} name={"answer" + answersCount} onChange={() => this.answer(answersCount)} style={{ width: "85%", backgroundColor: '#eee' }} />
                        {/* <Icon style={{ color: '#A21B1B', fontSize: '12px', padding: '0px 0px 0px 10px' }} onClick={() => { answersCount--; this.setState({ setAnswers: answersCount }) }} type="delete" theme="filled" /> */}
                        <br /><br />
                    </div>
                </div>
            );

            this.setState({
                attributeForm: array
            });
        }

        if (answersCount !== 5) {
            answersCount++;
        }

        console.log(answersCount)
        this.setState({
            setAnswers: answersCount
        })
    }

    inputField1 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer1"}><b>1.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer1" value={this.state.answerOne} onChange={(event) => this.setState({ answerOne: event.target.value })} /><br /><br />
        </div>
    )

    inputField2 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer2"}><b>2.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer2" value={this.state.answerTwo} onChange={(event) => this.setState({ answerTwo: event.target.value })} /><br /><br />
        </div>
    )

    inputField3 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer3"}><b>3.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer3" value={this.state.answerThree} onChange={(event) => this.setState({ answerThree: event.target.value })} /><br /><br />
        </div>
    )

    inputField4 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer4"}><b>4.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer4" value={this.state.answerFour} onChange={(event) => this.setState({ answerFour: event.target.value })} /><br /><br />
        </div>
    )

    inputField5 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer5"}><b>5.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer5" value={this.state.answerFive} onChange={(event) => this.setState({ answerFive: event.target.value })} /><br /><br />
        </div>
    )

    inputField6 = () => (
        <div>
            <label style={{ fontSize: '1.5em', color: '#075ECA' }} htmlFor={"answer6"}><b>6.</b> </label>
            <Input style={{ width: "85%", backgroundColor: '#eee' }} name="answer6" value={this.state.answerSix} onChange={(event) => this.setState({ answerSix: event.target.value })} /><br /><br />
        </div>
    )

    addMultipleChoice = () => {
        let { editingQuestionIndex, questionSet, questionSetCount, mode } = this.state
        var questionInput = this.state.multiple_choice_question

        const completeAddition = () => {
            analytics.track(analytics.events.CREATE_SURVEY_ADD_QUESTION_CLICKED, {
                Type: 'Multiple Choice',
                Question: questionInput,
                Answers: choiceArray,
                Editing: editingQuestionIndex,
                "Number of Questions": questionSet.length,
                mode
            })

            this.setState({
                questionSet,
                questionSetCount: questionSetCount++,
                multiple_choice_question: "",
                answerOne: null,
                answerTwo: null,
                answerThree: null,
                answerFour: null,
                answerFive: null,
                answerSix: null,
                answersCount: 0,
                editingQuestionIndex: null,
            })

            this.dismissModal()
        }

        var choiceArray = []
        if (this.state.answerOne != null) {
            choiceArray.push(this.state.answerOne)
        }
        if (this.state.answerTwo != null) {
            choiceArray.push(this.state.answerTwo)
        }
        if (this.state.answerThree != null) {
            choiceArray.push(this.state.answerThree)
        }
        if (this.state.answerFour != null) {
            choiceArray.push(this.state.answerFour)
        }
        if (this.state.answerFive != null) {
            choiceArray.push(this.state.answerFive)
        }
        if (this.state.answerSix != null) {
            choiceArray.push(this.state.answerSix)
        }

        if (editingQuestionIndex !== null) {
            // We're editing a specific question already typed in.
            let questionAtIndex = questionSet[editingQuestionIndex]

            // Modify this question at that index.
            questionAtIndex.question = questionInput

            // Modify the answers at this index?
            if (choiceArray.length > 0) {
                questionAtIndex.answers = choiceArray
            }

            completeAddition()
        } else {
            if (choiceArray.length > 0) {
                const questionObj = {
                    id: this.state.editingQuestionId,
                    question: questionInput,
                    type: 'multiple_choice',
                    answers: choiceArray
                }

                questionSet.push(questionObj)

               completeAddition()
            } else {
               failNotificationWithIcon("error")
            }
        }
    }

    addOpenEnded = () => {
        let { editingQuestionIndex, questionSet, questionSetCount, mode } = this.state
        const questionInput = this.state.open_ended_question

        if (editingQuestionIndex !== null) {
            // This means we're editing a question we typed in.. So...
            // Update that question instead of removing and adding to the bottom of the queue.
            let questionAtIndex = questionSet[editingQuestionIndex]

            // Modify this question at that index. 
            questionAtIndex.question = questionInput
        } else {
            // This is a new question - Add it to the end of the array
            const questionObj = {
                id: this.state.editingQuestionId,
                question: questionInput,
                type: 'open_ended',
            }
    
            console.log(questionObj);
            questionSet.push(questionObj)    
        }

        analytics.track(analytics.events.CREATE_SURVEY_ADD_QUESTION_CLICKED, {
            Type: 'Open Ended',
            Question: questionInput,
            Editing: editingQuestionIndex,
            mode,
            "Number of Questions": questionSet.length
        })

        this.setState({
            questionSet,
            questionSetCount: questionSetCount++,
            open_ended_question: "",
            answerOne: null,
            answerTwo: null,
            answerThree: null,
            answerFour: null,
            answerFive: null,
            answerSix: null,
            editingQuestionIndex: null
        })
        // document.getElementById("openEndedTextarea").value = "";
        // console.log(this.state.questionSet)

        this.dismissModal()
    }

    createOrUpdateSurvey = () => {
        const { mode } = this.state
        if (mode === 'NEW') {
            this.createSurvey()
        } else if (mode === 'EDIT') {
            this.updateSurvey()
        }
    }

    surveyActionButtonTitle = () => {
        const { mode } = this.state
        if (mode === 'NEW') {
            return 'Create Survey'
        } else if (mode === 'EDIT') {
            return 'Update Survey'
        }
    }

    createSurvey = () => {
        this.setState({ loading: true });
        var name = this.state.surveyName
        var description = this.state.surveyDescription
        const { frequency, smsCompletionMessage, ussdCompletionAlert } = this.state

        const surveySetup = {
            title: this.state.surveyName,
            description: this.state.surveyDescription,
            textwordId: this.state.surveyKeyword,
            isActive: this.state.surveyActive,
        }

        var surveyQuestions = this.state.questionSet
        const completion = { frequency, smsCompletionMessage, ussdCompletionAlert }
        console.log("@@ Completion: ", completion)

        axios.post('surveys/create', {
            setup: surveySetup,
            questions: surveyQuestions,
            completion
        })
            .then(response => {
                console.log("Response: ", response)
                const id = response.data.survey.id
                this.setState({
                    surveyName: null,
                    surveyDescription: null,
                    loading: false,
                    surveyKeyword: null,
                    questionSet: []
                }, () => {
                    this.success(name, description)
                    analytics.track(analytics.events.CREATE_SURVEY_SUCCESS, { 'Survey Id': id })
                })

            })
            .catch(error => {
                const message = errorMessage(error)
                console.log("Error: ", message)

               analytics.track(analytics.events.CREATE_SURVEY_FAILED, { 'Error': message })

                this.error(message)
                this.setState({ loading: false });
            })

        this.fetchTextwords()
    }

    updateSurvey = async () => {
        this.setState({ loading: true });
        const surveyId = this.props.editSurveyId



        const setup = {
            title: this.state.surveyName,
            description: this.state.surveyDescription,
            textwordId: this.state.surveyKeyword,
            isActive: this.state.surveyActive,
        }

        const { frequency, smsCompletionMessage, ussdCompletionAlert } = this.state
        const completion = {
            frequency,
            smsCompletionMessage,
            ussdCompletionAlert
        }

        const questions = this.state.questionSet

        // Pass in the exact same set of questions -
        // Edit should do nothing at the very least for those instances. 
        // const { id, type, question, answers } = questionObject

        console.log("@@ Setup: ", setup)
        console.log("@@ Questions: ", questions)
        
        const response = await axios.post(`surveys/edit/${surveyId}`, {
            setup,
            questions,
            completion
        })

        console.log("$$ Update responses: ", response)

        Modal.success({
            title: 'Survey Update Successfully',
            centered: true,
            content: (
                    <center>
                        <br />
                        <h2 style={{ color: '#396494' }}>{this.state.surveyName}</h2>
                        <h3 style={{ color: '#7E8294' }}><i>{this.state.surveyDescription}</i></h3>
                        <center><Avatar src={surveycreated} size={128} /></center>
                        <p style={{ fontWeight: 400, fontSize: '1.2em' }}>Your survey has been updated!</p><br />
                        <p>Anyone can take your updated survey by dialing <b>*718*788#</b> and using the keyword <b>{this.state.selectedTextword}</b>.</p>
                    </center>
            )
        });

        this.setState({ loading: false });
    }

    success = (name, description) => {
        Modal.success({
            title: 'Survey Created Successfully',
            centered: true,
            content: (
                    <center>
                        <br />
                        <h2 style={{ color: '#396494' }}>{name}</h2>
                        <h3 style={{ color: '#7E8294' }}><i>{description}</i></h3>
                        <center><Avatar src={surveycreated} size={128} /></center>
                        <p style={{ fontWeight: 400, fontSize: '1.2em' }}>Your survey is ready to be taken!</p><br />
                        <p>Anyone can take your survey by dialing <b>*718*788#</b> and using the keyword <b>{this.state.selectedTextword}</b>.</p>
                    </center>
            ),
            onOk() {
                // I hate these..
            },
        });

        this.setState({
            toNewSurvey: true
        })
    }

    error = (message) => {
        Modal.error({
            title: 'Failed to created your survey',
            content: (
                <div>
                    <p><b>{message}</b></p><br />
                </div>
            ),
            onOk() { },
        });
    }

    deleteQuestion = (index) => {
        console.log('@@ Set: ', this.state.questionSet)
        var checkToggle = window.confirm("Are you sure you want to delete this question?");
        if (checkToggle) {
            var questionSet = this.state.questionSet
            var questionSetCount = this.state.questionSetCount
            questionSet.splice(index, 1);
            this.setState({
                questionSet,
                questionSetCount: questionSetCount--
            }, () => {
                analytics.track(analytics.events.CREATE_SURVEY_DELETED, { 'Question Number': index, 'Questions': questionSet.length })
                console.log('@@ Update Set: ', this.state.questionSet)
            })
        }
    }

    editQuestionMultipleChoice = (id) => {
        const { mode, questionSet } = this.state
        if (mode === 'EDIT') {
            const question = questionSet[id]

            this.setState({
                editingQuestionId: question.id
            })

            if (!question.can_edit_multiple_choice_answers) {
                Modal.warning({
                    title: 'This question cannot be edited because it has already received responses',
                    content: (
                        <div>
                          <p>
                            In order to protect the integrity of your responses, you are unable to edit this question.
                          </p>
                        </div>
                      )
                })
                return
            }
        }

        this.showModal();
        this.setState({
            multipleChoiceCheck: true,
            openEndedCheck: false,
            type: false,
            editingQuestionIndex: id
        }, () => {
            analytics.track(analytics.events.CREATE_SURVEY_EDIT_CLICKED, { 'Question Number': id, Type: 'Multiple Choice' })
            console.log(id);
            var questionSet = this.state.questionSet;
            var answers = questionSet[id].answers;

            var question = questionSet[id].question;
            this.setState({
                multiple_choice_question: question,
                answerOne: (answers[0] !== undefined) ? answers[0] : null,
                answerTwo: (answers[1] !== undefined) ? answers[1] : null,
                answerThree: (answers[2] !== undefined) ? answers[2] : null,
                answerFour: (answers[3] !== undefined) ? answers[3] : null,
                answerFive: (answers[4] !== undefined) ? answers[4] : null,
                answerSix: (answers[5] !== undefined) ? answers[5] : null,
            })

            console.log(question)
        });
    }

    editQuestionOpenEnded = (index) => {
        const { mode, questionSet } = this.state
        if (mode === 'EDIT') {
            const question = questionSet[index]

            this.setState({
                editingQuestionId: question.id
            })

            if (!question.can_edit_multiple_choice_answers) {
                Modal.warning({
                    title: 'This question cannot be edited because it has already received responses',
                    content: (
                        <div>
                          <p>
                            In order to protect the integrity of your responses, you are unable to edit this question.
                          </p>
                        </div>
                      )
                })
                return
            }
        }

        this.showModal();

        analytics.track(analytics.events.CREATE_SURVEY_EDIT_CLICKED, { 'Question Number': index, Type: 'Open Ended' })

        var question = questionSet[index].question;

        this.setState({
            type: true,
            editingQuestionIndex: index,
            multipleChoiceCheck: false,
            openEndedCheck: true,
            open_ended_question: question
        })
    }

    updateSelectedKeyword = (keywordId, word) => {
        console.log(keywordId)
        var selectedTextword = word.props.name
        const { textwords } = this.state
        const found = textwords.filter(word => {
            return word.key === keywordId
        })

        let hasActiveSurvey = false
        if (found.length > 0) {
            const keyword = found[0]
            hasActiveSurvey = keyword.hasActiveSurvey
        }

        this.setState({ surveyKeyword: keywordId, keywordHasActiveSurvey: hasActiveSurvey, surveyActive: !hasActiveSurvey, selectedTextword: selectedTextword })
        analytics.track(analytics.events.CREATE_SURVEY_SELECT_KEYWORD, { Keyword: selectedTextword })
    }

    onFrequencyChange = frequency => {
        this.setState({
            frequency
        })
        analytics.track(analytics.events.CREATE_SURVEY_TOGGLE_FREQUENCY, { Frequency: frequency })
    }

    renderAnswers = answers => {
        var display = ""
        for (var i = 0; i < answers.length; i++) {
            display = display + " " + (i + 1) + ". " + answers[i] + " " + " "
        }
        return (
            <span>
                {display}
            </span>
        )
    }

    renderKeywordWithTag = (word, hasActiveSurvey) => {
        if (word) {
            return (
                <div>
                    {word + " "} {hasActiveSurvey ? <Tag color="green">Active Survey</Tag> : <Tag color="blue">No Active Survey</Tag>}  
                </div>
            )
        } else {
            return ''
        }
     }

    renderEmptyState = () => {
        return (
            <div>
                <p>There is nothing available here</p>
            </div>
        )
    }

    render() {
        if (this.state.mode === 'EMPTY') {
            return this.renderEmptyState()
        }

        if (this.state.surveyKeyword === "new_keyword") {
            return <Redirect to='/new_keyword' />
        }
        const { TextArea } = Input;
        const { keywordHasActiveSurvey, surveyActive } = this.state;

        const DragHandle = sortableHandle(() => <span ><Icon style={{ color: '#777777', fontSize: '22px', padding: '0px 20px 0px 0px' }} type="menu" /></span>);
        const SortableItem = sortableElement(({ value, type, answers, deleteIndex, editIndex }) => (

            <li className="disable-select" style={{ listStyle: 'none', cursor: 'pointer', marginBottom: '10px', marginLeft: '2px' }}>
                <Card style={{ width: '75%' }}>
                    <Col span={20}>
                        <span style={{ fontWeight: 600 }}>
                            {value} &emsp;&emsp; <span style={{ color: '#158E10', textTransform: 'uppercase' }}>{type.replace('_', ' ')}</span>
                        </span><br />
                        <hr style={{ marginLeft: '20px', marginRight: '20px' }} /><br />
                        {(type === "multiple_choice") ?
                            <span style={{ fontWeight: 600 }}>
                                {this.renderAnswers(answers)}
                            </span> :
                            <p></p>
                        }

                    </Col>
                    <Tooltip title="Delete"><span onClick={() => this.deleteQuestion(deleteIndex)}><Icon style={{ color: '#A21B1B', fontSize: '18px', padding: '0px 20px 0px 0px' }} type="delete" theme="filled" /></span></Tooltip>
                    {(type === "multiple_choice") ?
                        <Tooltip title="Edit"><span onClick={() => this.editQuestionMultipleChoice(editIndex)}><Icon style={{ color: '#026D4B', fontSize: '18px', padding: '0px 20px 0px 0px' }} type="setting" theme="filled" /></span></Tooltip> :
                        <Tooltip title="Edit"><span onClick={() => this.editQuestionOpenEnded(editIndex)}><Icon style={{ color: '#026D4B', fontSize: '18px', padding: '0px 20px 0px 0px' }} type="setting" theme="filled" /></span></Tooltip>
                    }
                    <DragHandle />
                </Card>
            </li>
        ));

        const SortableContainer = sortableContainer(({ children }) => {
            return <ul style={{ listStyle: 'none', marginLeft: '5px' }}>{children}</ul>;
        });

        return (
            <div>
                <div>
                    <Modal
                        title="Survey Question"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                        ]}
                    >
                        <div>
                            <Radio.Group id="radioGroup" buttonStyle="solid" defaultValue="multiple_choice" onChange={(event) => this.onQuestionType(event)} style={{ marginBottom: 16 }}>
                                <Radio.Button checked={this.state.multipleChoiceCheck} value="multiple_choice">Multiple Choice</Radio.Button>
                                <Radio.Button checked={this.state.openEndedCheck} value="open_ended">Open Ended</Radio.Button>
                            </Radio.Group>
                        </div>

                        {!this.state.type ?
                            <div>
                                <p>A multiple choice response allows people to <b>respond with a single number representing their answer.</b> For example, 1, 2, 3, 4 or 5.</p>
                                <h3>Your Question</h3>
                                <TextArea id="multipleChoiceTextarea" style={{ backgroundColor: "#eee" }} rows={5} value={this.state.multiple_choice_question} onChange={(event) => { this.setState({ multiple_choice_question: event.target.value }) }} />
                                <br /><br />
                                <h3>Answers</h3>
                                <this.inputField1 />
                                <this.inputField2 />
                                <this.inputField3 />
                                <this.inputField4 />
                                <this.inputField5 />
                                <this.inputField6 />
                                <p>* Unused fields will not appear in your survey</p>
                                {
                                    this.state.attributeForm.map(input => {
                                        return input
                                    })
                                }

                                <div align="right" style={{ paddingTop: '15px' }}>
                                    <Button onClick={this.handleCancel} type="link" style={{ color: '#900303', paddingRight: '10px', }}>Cancel</Button>
                                    <Button onClick={() => this.addMultipleChoice(1)} type="primary">Save Question</Button>
                                </div>
                            </div> :
                            <div>
                                <p>An open ended response allows people to <b>respond with whatever they like</b>. For example, "We had a great experience! The food was outstanding!".</p>
                                <span>Your Question</span>
                                <TextArea id="openEndedTextarea" style={{ backgroundColor: "#eee" }} rows={5} value={this.state.open_ended_question} onChange={(event) => { this.setState({ open_ended_question: event.target.value }) }} />

                                <div align="right" style={{ paddingTop: '15px' }}>
                                    <Button onClick={this.handleCancel} type="link" style={{ color: '#900303', paddingRight: '10px', }}>Cancel</Button>
                                    <Button onClick={() => this.addOpenEnded(1)} type="primary">Save Question</Button>
                                </div>
                            </div>
                        }

                    </Modal>
                </div>

                <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Collapse.Panel header={<span style={{ fontSize: '1.5em', fontWeight: 600 }}>Basic Information <span style={{ fontSize: '0.5em' }}><i>(Show/Hide)</i></span></span>} key="1">
                        <div>
                            <div style={{ marginBottom: '1.5%' }}>
                                <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Survey Title (people will not see)</span>
                                <Input placeholder="" value={this.state.surveyName} onChange={(event) => this.setState({ surveyName: event.target.value })} />
                            </div>

                            <div style={{ marginBottom: '1.5%' }}>
                                <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Survey Description (people will not see)</span>
                                <Input placeholder="" value={this.state.surveyDescription} onChange={(event) => this.setState({ surveyDescription: event.target.value })} />
                            </div>

                                        <div style={{ marginBottom: '1.5%' }}>
                                            <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Please select which keyword this survey is for (Customers will type this keyword to take the survey) </span>

                                            <Select disabled={this.state.mode !== 'NEW'} ref="keywordchoice" size={'large'} style={{ width: '100%' }} onChange={(value, name) => this.updateSelectedKeyword(value, name)} value={this.renderKeywordWithTag(this.state.selectedTextword, this.state.keywordHasActiveSurvey)}>
                                                {
                                                    this.state.textwords.map(textword => (
                                                        <Select.Option key={textword.key} value={textword.key} name={textword.word}>
                                                            {this.renderKeywordWithTag(textword.word, textword.hasActiveSurvey)}
                                                        </Select.Option>
                                                    ))
                                                }
                                                <Select.Option key="new_keyword" value="new_keyword"> + Create a new keyword </Select.Option>
                                            </Select>
                                        </div>

                                        {this.state.mode === 'NEW' && (
                                            <div>
                                                <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Active Status <br /></span>
                                                <Switch size="large" defaultChecked onChange={(checked) => this.toggleSurveyActivity(checked)} disabled={keywordHasActiveSurvey} checked={surveyActive} />
                                                {keywordHasActiveSurvey && <p style={{ color: 'blue', maxWidth: 500, marginTop: 8 }}>The keyword you have selected already has an active survey. You can still create this survey, but it will not be activated. To activate it, first deactivate the older survey using this keyword, and then reactivate this new one.</p>}
                                            </div>
                                        )}
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel header={<span style={{ fontSize: '1.5em', fontWeight: 600 }}>Survey Setup <span style={{ fontSize: '0.5em' }}><i>(Show/Hide)</i></span></span>} key="2">
                        <Row>
                            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                {this.state.questionSet.map((item, index, ) => (
                                    (item.type === "multiple_choice") ?
                                        <SortableItem key={`item-${index}`} index={index} value={item.question} type={item.type} answers={item.answers} deleteIndex={index} editIndex={index} /> :
                                        <SortableItem key={`item-${index}`} index={index} value={item.question} type={item.type} deleteIndex={index} editIndex={index} />
                                ))}
                            </SortableContainer>
                            <Button onClick={this.showAddModal} type="dashed" size={'large'} style={{ width: '80%', fontSize: '18px', color: '#1890ff', padding: '0% 10% 0% 10%' }}>
                                <Icon type="file-add" theme="filled" /> Add Question
                            </Button>
                            <br /><br />
                        </Row>
                    </Collapse.Panel>

                    <Collapse.Panel header={<span style={{ fontSize: '1.5em', fontWeight: 600 }}>Survey Completion <span style={{ fontSize: '0.5em' }}><i>(Show/Hide)</i></span></span>} key="3">
                        <div style={{ marginBottom: '1.5%' }}>
                            <Row>
                                <Col xs={19} sm={19} md={19} lg={19}>
                                    <span style={{ fontWeight: 600, fontSize: '1.1em' }}>People can take my survey: </span>
                                    <Select
                                        size={'large'}
                                        style={{ width: '100%', backgroundColor: (store.isPremium) ? '#eee' : '#fff' }}
                                        value={this.state.frequency}
                                        onChange={this.onFrequencyChange}
                                        disabled={!store.isPremium}>
                                        <Select.Option value="once">Once</Select.Option>
                                        <Select.Option value="daily">Every day</Select.Option>
                                        <Select.Option value="weekly">Each week</Select.Option>
                                        <Select.Option value="monthly">Each month</Select.Option>
                                        <Select.Option value="unlimited">Unlimited</Select.Option>
                                    </Select>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3}>
                                    {(!store.isPremium) &&
                                        <Popover content={premium_frequency_completion} title="UPGRADE TO PREMIUM" trigger="hover">
                                            <Link to="/pricing">
                                                <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                                                    <center>
                                                        <Icon style={{ backgroundColor: 'white', color: 'green', fontSize: '2em' }} type="plus-circle" theme="filled" />
                                                        <br /><span style={{ fontSize: '0.7em' }}>CHANGE</span>
                                                    </center>
                                                </div>
                                            </Link>
                                        </Popover>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <div style={{ marginBottom: '1.5%' }}>
                                <Row>
                                    <Col xs={19} sm={19} md={19} lg={19}>
                                        <span style={{ fontWeight: 600, fontSize: '1.1em' }}>USSD Completion Alert (People will see this in the USSD prompt as soon as they complete the survey):
                                            <Input
                                                style={{ backgroundColor: (store.isPremium) ? '#fff' : '#eee' }}
                                                disabled={!store.isPremium}
                                                value={this.state.ussdCompletionAlert}
                                                placeholder="USSD Completion Alert..."
                                                onChange={(event) => this.setState({ ussdCompletionAlert: event.target.value })}
                                            />
                                        </span>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3}>
                                        {(!store.isPremium) ?
                                            <Popover content={premium_ussd_completion} title="UPGRADE TO PREMIUM" trigger="hover">
                                                <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                                                    <Link to="/pricing">
                                                        <center>
                                                            <Icon style={{ backgroundColor: 'white', color: 'green', fontSize: '2em' }} type="plus-circle" theme="filled" />
                                                            <br /><span style={{ fontSize: '0.7em' }}>CHANGE</span>
                                                        </center>
                                                    </Link>
                                                </div>

                                            </Popover> :
                                            <div>

                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ marginBottom: '1.5%' }}>
                                <Row>
                                    <Col xs={19} sm={19} md={19} lg={19}>
                                        <span style={{ fontWeight: 600, fontSize: '1.1em' }}>SMS Completion Message (People will get this when they complete your survey):
                                            <Input
                                                style={{ backgroundColor: (store.isPremium) ? '#fff' : '#eee' }}
                                                disabled={!store.isPremium}
                                                value={this.state.smsCompletionMessage}
                                                placeholder="SMS Completion Message..."
                                                onChange={(event) => this.setState({ smsCompletionMessage: event.target.value })}
                                            />
                                        </span>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3}>
                                        {(!store.isPremium) ?
                                            <Popover content={premium_sms_completion} title="UPGRADE TO PREMIUM" trigger="hover">
                                                <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                                                    <Link to="/pricing">
                                                        <center>
                                                            <Icon style={{ backgroundColor: 'white', color: 'green', fontSize: '2em' }} type="plus-circle" theme="filled" />
                                                            <br /><span style={{ fontSize: '0.7em' }}>CHANGE</span>
                                                        </center>
                                                    </Link>
                                                </div>
                                            </Popover> :
                                            <div>

                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <Button type="primary" size={'large'} icon="save" loading={this.state.loading} onClick={() => this.createOrUpdateSurvey()}>
                            {this.surveyActionButtonTitle()}
                        </Button>
                    </Collapse.Panel>
                </Collapse >

            </div >)
    }
}

const premium_ussd_completion = (
    <div>
        <p>To change the USSD alert received right when a customer completes your survey, please upgrade to a premium account.</p>
        <Link to="/pricing">Click here to learn more and upgrade your account!</Link>
    </div>
);

const premium_sms_completion = (
    <div>
        <p>To change the SMS message received once a customer completes your survey, please upgrade to a premium account.</p>
        <Link to="/pricing">Click here to learn more and upgrade your account!</Link>
    </div>
);

const premium_frequency_completion = (
    <div>
        <p>To change the number times your respondents can answer your survey in a certain time period.</p>
        <Link to="/pricing">Click here to learn more and upgrade your account!</Link>
    </div>
);

export default observer(CreateSurvey)