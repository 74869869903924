import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Icon, Input, Tag, Button, Table, Spin } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import moment from "moment";
import analytics from "../config/analytics";

export default class Keywords extends React.Component {
  state = {
    searchText: "",
    textwords: [],
    isProcessing: true
  };

  componentDidMount() {
    const token = localStorage.getItem("accessToken");
    axios.defaults.headers["Authorization"] = token;
    console.log("Token in keywords.js: ", token);

    this.fetchTextwords();

    analytics.track(analytics.events.KEYWORD_VIEWED);
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
        </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
        </Button>
        </div>
      ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  fetchTextwords = () => {
    axios
      .get("/accounts")
      .then(response => {
        console.log("here", response);
        const textwords = response.data.textwords.map(word => {
          return {
            key: word.id,
            auto_reply: word.auto_reply,
            word: word.word,
            contacts_count: word.contacts_count,
            created_at: moment(word.created_at).format("LLL"),
            has_active_survey: word.has_active_survey
          };
        });
        this.setState({
          textwords,
          isProcessing: false
        });
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  render() {
    const columns = [
      {
        title: "Keyword",
        dataIndex: "word",
        key: "word",
        ...this.getColumnSearchProps("word")
      },
      {
        title: "Created On",
        dataIndex: "created_at",
        key: "created_at",
        ...this.getColumnSearchProps("created_at")
      },
      {
        title: "Has Active Survey",
        dataIndex: "has_active_survey",
        key: "has_active_survey",
        ...this.getColumnSearchProps("has_active_survey"),
        render: isActive => {
          return isActive ? (
            <Tag color="#53AE61">Active</Tag>
          ) : (
              <Tag color="blue">No Active Survey</Tag>
            );
        }
      }
    ];

    return (
      <div>
        <div style={{ marginTop: "1%", marginBottom: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>
            My Keywords
            <Link to="/new_keyword">
              <Button
                style={{ fontSize: "0.8em", fontWeight: 600, marginLeft: "2%" }}
                type="primary"
              >
                <Icon type="plus-circle" theme="filled" />
                Register New Keyword
              </Button>
            </Link>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#FBFBFB",
            border: "0.5px solid #E9E9E9",
            borderRadius: "2px",
            padding: "1%"
          }}
        >
          {this.state.isProcessing ? (
            <center>
              <Spin />
            </center>
          ) : (
              <div>
                <div style={{ marginBottom: '10px', fontSize: "1.5em" }}>
                  See your available keywords, and register new keywords <br />
                  Respondents will use your keyword to answer your survey
                </div>
                <Table columns={columns} dataSource={this.state.textwords} />
              </div>
            )}
        </div>
      </div>
    );
  }
}
