import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import store from "./config/store";
import { isSignedIn, onSignOut } from "./config/auth";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";
import Logo from "./assets/images/favicon-3.png";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Intercom from "react-intercom";

// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import { Layout, Menu, Icon, Dropdown, Avatar, Row, Col } from "antd";

import { handleError } from "./util/helpers";

const { Header, Sider, Content, Footer } = Layout;

class App extends React.Component {
  state = {
    collapsed: false,
    user: null
  };

  async componentDidMount() {
    console.log("App.js component did mount");
    try {
      const signedIn = await isSignedIn();
      if (signedIn) {
        await this.hydrateStoreAndCompleteSignIn();
      }
    } catch (error) {
      handleError(error);
    }
  }

  hydrateStoreAndCompleteSignIn = async () => {
    await store.fetchCurrentUser();
    await store.fetchAllSurveys();
  };

  onSidebarMenuSelect = event => {
    console.log("Event: ", event);
    console.log("We got here: ", this.props);

    switch (event.key) {
      case "dashboard":
        this.props.history.push("/");
        break;

      default:
        this.props.history.push(event.key);
        break;
    }
  };

  onHeaderMenuSelect = async event => {
    console.log("Signoutæ");
    await onSignOut();
    this.props.history.push("/login");
  };

  onBillingMenuSelect = async event => {
    console.log("Billing Menu: ", event);

    switch (event.key) {
      case "topup":
        window.location.href = "https://web.chippercash.com/me/ussdsurveys";
        break;

      case "history":
        this.props.history.push("billing");
        break;

      default:
        break;
    }
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: false
    });
  };

  toggleExpand = () => {
    this.setState({
      collapsed: true
    });
  };

  toggleSideNav = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  };

  render() {
    console.log(store.displayName);
    console.log(store.accountId);
    console.log(store.email);
    const user = {
      user_id: store.accountId,
      email: store.email,
      name: store.displayName,
      phone: store.phone
    };
    const menu = (
      <Menu style={{ width: "50%" }} onClick={this.onHeaderMenuSelect}>
        <Menu.Item key="signout">
          <span>
            <Icon style={{ color: "#AD1047" }} type="logout" /> Sign out
          </span>
        </Menu.Item>
      </Menu>
    );

    const billing = (
      <Menu onClick={this.onBillingMenuSelect}>
        <Menu.Item
          key={"topup"}
          target="_blank"
          href="https://web.chippercash.com/me/ussdsurveys"
        >
          <Row>
            <Col xs={2} sm={2} md={2} lg={2}>
              <Icon type="up-circle" theme="twoTone" twoToneColor="1021AD" />
            </Col>
            <Col xs={22} sm={22} md={22} lg={22}>
              To top up, your balance please go to our
              <br />
              <a
                target="_blank"
                href="https://web.chippercash.com/me/ussdsurveys"
              >
                {" "}
                payment page [Click Here]
              </a>
              , and enter <br />"
              <a
                target="_blank"
                href="https://web.chippercash.com/me/ussdsurveys"
              >
                {store.email}
              </a>
              " as a note.
            </Col>
          </Row>
        </Menu.Item>
        <Menu.Item key={"history"}>
          <Row>
            <Col xs={2} sm={2} md={2} lg={2}>
              <Icon style={{ color: "#52c41a" }} type="history" />
            </Col>
            <Col xs={22} sm={22} md={22} lg={22}>
              Balance History
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        {/* <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0
          }}
        >
          <div className="logo" style={{ color: 'white', textAlign: 'center' }}> USSDSURVEYS</div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["dashboard"]}
            onSelect={this.onSidebarMenuSelect}
            inlineCollapsed={true}
          >
            <Menu.Item key="dashboard">
              <Icon type="dashboard" />
              <span className="nav-text">Dashboard</span>
            </Menu.Item>
            <Menu.Item key="surveys">
              <Icon type="ordered-list" />
              <span className="nav-text">Surveys</span>
            </Menu.Item>
            <Menu.Item key="keywords">
              <Icon type="key" />
              <span className="nav-text">Keywords</span>
            </Menu.Item>
            <Menu.Item key="contacts">
              <Icon type="user" />
              <span className="nav-text">Contacts</span>
            </Menu.Item>
          </Menu>
        </Sider> */}
        <Intercom appID="mm2qwj9g" {...user} />
        <SideNav
          expanded={this.state.collapsed}
          onSelect={selected => {
            // Add your code here
            switch (selected) {
              case "dashboard":
                this.props.history.push("/");
                this.toggleCollapsed();
                break;

              default:
                this.props.history.push(selected);
                this.toggleCollapsed();
                break;
            }
          }}
          style={{ backgroundColor: "#111", position: "fixed" }}
        >
          <SideNav.Toggle onClick={() => this.toggleSideNav()} />
          <SideNav.Nav defaultSelected="dashboard">
            <NavItem eventKey="dashboard">
              <NavIcon>
                <Icon type="dashboard" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Dashboard</span>
              </NavText>
            </NavItem>
            <NavItem eventKey="surveys">
              <NavIcon>
                <Icon type="ordered-list" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Survey</span>
              </NavText>
            </NavItem>
            <NavItem eventKey="keywords">
              <NavIcon>
                <Icon type="key" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Keywords</span>
              </NavText>
            </NavItem>
            <NavItem eventKey="campaigns">
              <NavIcon>
                <Icon type="notification" />
              </NavIcon>
              <NavText>
                <span className="nav-text">SMS Campaigns</span>
              </NavText>
            </NavItem>
            <NavItem eventKey="account">
              <NavIcon>
                <Icon type="user" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Account</span>
              </NavText>
            </NavItem>
            {/* <NavItem eventKey="referrals">
              <NavIcon>
                <Icon type="heart" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Earn Credit</span>
              </NavText>
            </NavItem> */}
            {!store.isPremium && (
              <NavItem eventKey="pricing">
                <NavIcon>
                  <Icon type="star" />
                </NavIcon>
                <NavText>
                  <span className="nav-text">Pricing</span>
                </NavText>
              </NavItem>
            )}
            {store.isAdmin && (
              <NavItem eventKey="allaccounts">
                <NavIcon>
                  <Icon type="global" />
                </NavIcon>
                <NavText>
                  <span className="nav-text">All Accounts</span>
                </NavText>
              </NavItem>
            )}
          </SideNav.Nav>
        </SideNav>

        <Layout style={{ marginLeft: 64 }}>
          <Header
            style={{
              background: "#111111",
              // background: "#041427",
              padding: 0,
              position: "fixed",
              top: 0,
              // left: 64,
              // right: 0,
              width: "100%",
              display: "flex",
              zIndex: 5
            }}
          >
            {/* <Row> */}
            <Col md={14} lg={14}>
              <div className={this.state.isLogoShowing ? "showLogo" : "noLogo"}>
                <img src={Logo} size={42} />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <Dropdown overlay={billing} style={{ float: "right" }}>
                <div>
                  <a
                    style={{
                      color: "white",
                      fontSize: "0.8em",
                      border: "1px dashed white",
                      padding: "10px",
                      backgroundColor: "#434343"
                    }}
                  >
                    <Icon type="bank" /> GHS {`${store.balance}`}
                  </a>
                </div>
              </Dropdown>
            </Col>
            <Col md={6} lg={6}>
              <Dropdown overlay={menu} style={{ float: "right" }}>
                <div>
                  <a
                    className="ant-dropdown-link"
                    style={{
                      color: "white",
                      fontSize: "1.0em",
                      marginLeft: "1px"
                    }}
                  >
                    <b style={{ marginRight: 8 }}>{" " + store.displayName}</b>
                    <Icon type="down" />
                  </a>
                </div>
              </Dropdown>
            </Col>

            {/* </Row> */}
          </Header>
          <Content
            style={{
              margin: "80px 16px 0",
              overflow: "initial"
            }}
          >
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <Link to="/referralterms">
              <span style={{ textDecoration: 'underline' }}>Referral Terms</span>
            </Link>
            {" "}
            <Link to="/privacypolicy">
              <span style={{ textDecoration: 'underline' }}>Privacy</span>
            </Link>
            <br />
            ©2019 USSD Surveys Created with{" "}
            <span role="img" aria-label="heart">
              💛
            </span>{" "}
            in Ashongman
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(observer(App));

/*
            <NavItem eventKey="contacts">
              <NavIcon>
                <Icon type="user" />
              </NavIcon>
              <NavText>
                <span className="nav-text">Contacts</span>
              </NavText>
            </NavItem>
            */
