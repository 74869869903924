import React from "react";
import axios from "axios";
import { Card, Col, Row, List, Icon, Input, Table, Button, Spin } from "antd";
import "../index.css";
import moment from "moment";
import { observer } from "mobx-react";
import Highlighter from "react-highlight-words";

class AllAccounts extends React.Component {
  state = {
    allusers: "",
    isProcessing: true
  };

  componentWillMount() {
    this.fetchAllUsers();
  }

  fetchAllUsers = async () => {
    const response = await axios.get("/accounts/admin/all");
    console.log(response.data.users);
    this.setState({
      allusers: response.data.users,
      isProcessing: false
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  // Temporary pausing for now
  goToProfile = id => {
    this.props.history.push(`profile/${id}`);
  };

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend", "ascend"]
        //        render: text => <a onClick={id => this.goToProfile(text)}>{text}</a>
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        ...this.getColumnSearchProps("first_name")
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        ...this.getColumnSearchProps("last_name")
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email")
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...this.getColumnSearchProps("phone")
      },
      {
        title: "No. SMS",
        dataIndex: "num_sms_sent",
        key: "num_sms_sent",
        sorter: (a, b) => a.num_sms_sent - b.num_sms_sent,
        sortDirections: ["descend", "ascend"]
      },

      {
        title: "No. USSD ",
        dataIndex: "num_ussd_sessions",
        key: "num_ussd_sessions",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.num_ussd_sessions - b.num_ussd_sessions,
        sortDirections: ["descend", "ascend"]
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        sorter: (a, b) => a.balance - b.balance,
        sortDirections: ["descend", "ascend"]
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        sorter: (a, b) =>
          moment(a.created_at).unix() - moment(b.created_at).unix(),
        sortDirections: ["descend", "ascend"],
        render: text => <span>{moment(text).format("LLL")}</span>
      },
      {
        title: "Last Presence",
        dataIndex: "last_presence",
        key: "last_presence",
        sorter: (a, b) =>
          moment(a.last_presence).unix() - moment(b.last_presence).unix(),
        sortDirections: ["descend", "ascend"],
        render: text => <span>{moment(text).format("LLL")}</span>
      },
      {
        title: "Billing Start",
        dataIndex: "billing_start",
        key: "billing_start",
        sorter: (a, b) => {
          const start = moment(a.billing_start).unix() || 0;
          const end = moment(b.billing_start).unix() || 0;
          return start - end;
        },
        render: text => <span>{moment(text).format("LLL")}</span>
      },
      {
        title: "Billing End",
        dataIndex: "billing_end",
        key: "billing_end",
        sorter: (a, b) => {
          const start = moment(a.billing_start).unix() || 0;
          const end = moment(b.billing_start).unix() || 0;
          return start - end;
        },
        render: text => <span>{moment(text).format("LLL")}</span>
      },
      {
        title: "Notes",
        dataIndex: "admin_notes",
        key: "admin_notes"
      }
    ];

    return (
      <div>
        <div>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>All Accounts</div>
          {this.state.isProcessing ? (
            <center>
              <Spin />
            </center>
          ) : (
            <Table
              pagination={false}
              dataSource={this.state.allusers}
              columns={columns}
            />
          )}
        </div>
      </div>
    );
  }
}

export default observer(AllAccounts);
