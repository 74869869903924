import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Row,
} from "antd";
import "../index.css";
import store from "../config/store";
import { observer } from "mobx-react";
import analytics from "../config/analytics";

const standard = [
  "Unlimited USSD Surveys.",
  "Unlimited Keyword Registration.",
  "Unlimited USSD Survey Responses.",
  "SMS Reminders For Incomplete Surveys.",
  "Data Visualisation of Responses."
];

const premium = [
  "Unlimited USSD Surveys.",
  "Unlimited Keyword Registration.",
  "Unlimited USSD Survey Responses.",
  "SMS Reminders For Incomplete Surveys.",
  "Data Visualisation of Responses.",
  "Customized SMS Sender ID.",
  "Customized USSD Completion Alerts.",
  "Customized SMS Completion Messages To Respondents.",
  "Customized Frequency For Your USSD Session (Once / Weekly / Monthly / Unlimited).",
  "Excel & CSV Survey data export."
];
class Referral extends React.Component {
  state = {
    firstName: "",
    referralLink: "",
    referralCode: "",
    pending: "",
    settled: "",
  };

  componentWillMount = () => {
    analytics.track(analytics.events.PRICING_VIEWED);
    this.getReferralCode()
  };

  getReferralCode = async () => {
    const response = await axios.get("/referrals/code");
    console.log(response)
    var referralLink = response.data.link
    var referralCode = response.data.cpde
    var pending = response.data.stats.pending
    var settled = response.data.stats.settled

    this.setState({
      referralLink,
      referralCode,
      pending,
      settled
    })
  }

  copyReferral = () => {
    navigator.clipboard.writeText(this.state.referralLink)
  }

  // handlePremiumClicked = () => {
  //   IntercomAPI(
  //     "showNewMessage",
  //     "Hello there! I would like to upgrade my account to a PREMIUM ACCOUNT. Please assist me on the next steps to do so. Thank you!"
  //   );
  //   IntercomAPI("trackEvent", "Premium - Clicked");
  //   analytics.track(analytics.events.PRICING_PREMIUM_CLICKED);
  // };

  render() {

    var stats = this.state.stats
    return (
      <div>
        <div>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>Referral</div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card>
                <p
                  style={{
                    backgroundColor: "#10336f",
                    color: "white",
                    padding: "35px 30px",
                    fontWeight: "bold"
                  }}
                >
                  <span style={{ fontSize: "1.5em" }}>Earn Up To GHS 50.00 When You Refer Others to USSD Surveys!</span>
                </p>
                <br />
                <p>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.5em",
                      color: "#10336f"
                    }}
                  >
                    How Does it work?
                    </div>
                  <br />
                  <h3>1. Copy Your Link Above And Share With Others</h3>
                  <h3>2. Your referral registers an account with USSD Surveys through your link and upgrades to a premium account</h3>
                  <h3>3. You get <b>50 GHS</b> in USSD & SMS credit on USSD Surveys</h3>
                  <br />
                </p>

                <input style={{ fontSize: '1.5em', width: '60%' }} value={this.state.referralLink} />
                <button
                  style={{ backgroundColor: '#6a1547', color: 'white', fontSize: '1.2em', padding: '9px 20px' }}
                  onClick={() => this.copyReferral()}> Copy Link
                </button>

                <div align="center" style={{ paddingTop: '20px' }}>
                  <Col xs={4} sm={4} md={4} lg={4}>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <div style={{ border: '1px solid grey' }}>
                      <br /><h2>{this.state.pending}</h2>
                      <h4 style={{ color: '#7E2A3F' }} align="center">SIGNUPS</h4>
                    </div>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <div style={{ border: '1px solid grey' }}>
                      <br /><h2>{this.state.settled}</h2>
                      <h4 style={{ color: '#10336f' }} align="center">UPGRADED</h4>
                    </div>
                  </Col>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default observer(Referral);
