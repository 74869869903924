import React from "react";
import { Form, Icon, Input, Button, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import store from "../config/store";
import { handleError } from "../util/helpers";
import Logo from "../assets/images/favicon-3.png";
import Intercom from "react-intercom";

class NormalLoginForm extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const { email, password } = values;
        // Fire something
        try {
          const response = await axios.post("/accounts/login", {
            email,
            password
          });
          const account = response.data.account;
          console.log("@@ Account: ", account);

          this.completeAuthentication(account.token);
          store.setCurrentUser(account.first_name, account.last_name);
        } catch (error) {
          handleError(error);
          this.setState({ loading: false });
        }
      }
    });
  };

  completeAuthentication = token => {
    localStorage.setItem("accessToken", token);
    axios.defaults.headers["Authorization"] = token;
    this.setState({ loading: false });
    this.props.history.push("/dashboard");
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "#f2f4f8",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Intercom appID="mm2qwj9g" />
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card title={"Log in to USSD SURVEYS"} style={{ minWidth: "400px" }}>
              <Form onSubmit={this.handleSubmit} style={{ minWidth: "400px" }}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [{ required: true, message: "Please input your email!" }]
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={this.state.loading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Log in
              </Button>
                  Dont have an account? <Link to="/register">register now!</Link>
                </Form.Item>
              </Form>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card style={{ minWidth: "300px", minHeight: "300px" }}>
              <h3 style={{ fontWeight: 'bold' }}>EARN MONEY ON USSD SURVEYS</h3>
              <p>💰 Earn up to 30% on everyone who purchases premium</p>
              <p>💙 Receive a percentage for every SMS and Survey</p>
              <p>💌 Mail us for details <a href="mailto:team@ussdsurveys.com">team@ussdsurveys.com</a></p>
              <p>📝 Login to see referral terms and learn more </p>
            </Card>
          </Col>

        </Row>

      </div>
    );
  }
}

export default Form.create({ name: "normal_login" })(NormalLoginForm);
