import React from "react";
import {
  Card,
  Col,
  Row,
  Icon,
  Input,
  Tooltip,
  Button,
  Table,
  notification,
  Modal,
  Avatar
} from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import created from "../assets/images/created.png";
import analytics from "../config/analytics";

const successNotificationWithIcon = (type, textword) => {
  notification[type]({
    message: "Nice!",
    description: `You have successfully registered the keyword ${textword}. You can now create surveys using this keyword`
  });
};

const failNotificationWithIcon = (type, textword) => {
  notification[type]({
    message: "Sorry!",
    description: `Failed to register that textword. That keyword has already been taken.`
  });
};

export default class RegisterKeyword extends React.Component {
  state = {
    textwordAvailable: false,
    textwordSelected: null,
    textwordID: null,
    attemptTextword: false,
    toNewSurvey: false
  };

  componentWillMount = () => {
    analytics.track(analytics.events.KEYWORD_REGISTER_VIEWED);
  };

  findAvailableTextword = textwordToCheck => {
    console.log(textwordToCheck);
    if (
      textwordToCheck.includes('"') ||
      textwordToCheck.includes("'") ||
      textwordToCheck.includes(",") ||
      textwordToCheck.includes("/") ||
      textwordToCheck.includes("-") ||
      textwordToCheck.includes(".")
    ) {
      this.setState({
        textwordAvailable: false,
        attemptTextword: true,
        textwordSelected: "Special character options"
      });
    } else {
      if (textwordToCheck.length < 1) {
        this.setState({
          textwordAvailable: false,
          attemptTextword: true,
          textwordSelected: "This"
        });
      } else {
        axios
          .get(`/textwords/available/${textwordToCheck}`)
          .then(response => {
            console.log(response);
            this.setState({
              textwordAvailable: response.data,
              textwordSelected: textwordToCheck,
              attemptTextword: true
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  success = textword => {
    Modal.success({
      title: "Keyword Registered Successfully",
      content: (
        <div>
          <center>
            <Avatar src={created} size={128} />
          </center>
          <p>
            <b>{textword}</b> has been registered!
          </p>
          <br />
          <p>
            You have successfully registered the keyword <b>{textword}</b>. You
            can now create a new survey with this keyword.{" "}
          </p>
          <p>
            <span style={{ color: "#396494", fontWeight: 600 }}>
              Click "OK" to continue to the survey creation Screen
            </span>
          </p>
        </div>
      ),
      onOk() {}
    });

    this.setState({
      toNewSurvey: true
    });
  };

  error = textword => {
    Modal.error({
      title: "Error",
      content: (
        <div>
          <p>
            Failed to register keyword: <b>{textword}</b>
          </p>
          <br />
          <p>
            The textword is already registered. If this error persists please
            contact support
          </p>
        </div>
      ),
      onOk() {}
    });
  };

  grabTextword = () => {
    const { textwordSelected } = this.state;
    axios
      .post("/textwords", {
        word: textwordSelected
      })
      .then(response => {
        console.log(response);
        this.setState({
          textwordID: response.data.response.id
        });
        this.success(textwordSelected);
        analytics.track(analytics.events.KEYWORD_REGISTERD_CLICKED, {
          Keyword: textwordSelected
        });
      })
      .catch(error => {
        this.error(textwordSelected);
      });
  };

  render() {
    if (this.state.toNewSurvey === true) {
      return <Redirect to="/new_survey" />;
    }

    return (
      <div>
        <center>
          <p>
            The keyword is what your survey customers will enter after dialling
            the unique shortcode (e.g
            <span style={{ color: "#045ED8" }}>
              <i>*718*788#</i>
            </span>
            ) to take your survey
          </p>
          <Input.Search
            placeholder="Search available keyword"
            enterButton="Register"
            size="large"
            onSearch={() => this.grabTextword()}
            onChange={event => this.findAvailableTextword(event.target.value)}
            style={{ width: "70%" }}
          />
          {this.state.attemptTextword ? (
            <div>
              {this.state.textwordAvailable ? (
                <div style={{ color: "green", fontSize: "1.2em" }}>
                  <b>
                    {this.state.textwordSelected} is available. Click Register
                    to own it!
                  </b>
                </div>
              ) : (
                <div style={{ color: "red", fontSize: "1.2em" }}>
                  <b>{this.state.textwordSelected} is not available!</b>
                </div>
              )}
            </div>
          ) : (
            <div style={{ fontSize: "1.2em" }}>
              <b>Enter a keyword to proceed</b>
            </div>
          )}
          {/* <p style={{ fontWeight: 600, fontSize: '1.4em' }}>Enter a textword to check availability</p> */}
        </center>
      </div>
    );
  }
}
