import React from "react";
import { Card, Col, Row, Icon, Input, Tooltip, Button, Table } from 'antd';
import Highlighter from 'react-highlight-words';

const data = [
  {
    key: '1',
    name: 'Orange',
    age: 'Apr 21st @ 9:07 pm',
    address: 'Yes',
  },
  {
    key: '2',
    name: 'Innout',
    age: 'Apr 21st @ 9:07 pm',
    address: 'Yes',
  },
  {
    key: '3',
    name: 'Wololo',
    age: 'Apr 21st @ 9:07 pm',
    address: 'Yes',
  },
  {
    key: '4',
    name: 'Redder',
    age: 'Apr 21st @ 9:07 pm',
    address: 'No',
  },
];

export default class Contacts extends React.Component {

  state = {
    searchText: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Number',
        dataIndex: 'address',
        key: 'number',
        ...this.getColumnSearchProps('address'),
      },
      {
        title: 'Textword',
        dataIndex: 'age',
        key: 'textword',
        ...this.getColumnSearchProps('age'),
      },
      {
        title: 'Opt-In Method',
        dataIndex: 'name',
        key: 'optinm',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Opt-In Date',
        dataIndex: 'address',
        key: 'optind',
        ...this.getColumnSearchProps('address'),
      },
      {
        title: 'Settings',
        dataIndex: 'age',
        key: 'settings',
        ...this.getColumnSearchProps('age'),
      },
    ];

    return (
      <div>

        <div style={{ marginTop: '1%', marginBottom: '1%' }}>
          <Row>
            <div style={{ fontSize: '1.5em', fontWeight: 600 }}>
              <Col span={12}>
                My Keywords
            </Col>
              <Col sm={4} md={4} lg={4}>
                <Button style={{ fontSize: '0.7em', fontWeight: 600, marginLeft: '2%' }} type="primary"><Icon type="plus-circle" theme="filled" />
                  Import Contacts
            </Button>
              </Col>
              <Col sm={4} md={4} lg={4}>
                <Button style={{ fontSize: '0.7em', fontWeight: 600, marginLeft: '2%' }} type="primary"><Icon type="plus-circle" theme="filled" />
                  Add Contact
            </Button>
              </Col>
            </div>
          </Row>
        </div>
        <div style={{ backgroundColor: '#FBFBFB', border: "0.5px solid #E9E9E9", borderRadius: "2px", padding: "1%" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    )
  }
}
