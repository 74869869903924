import React from "react";
import { Card, Col, Row, Icon, Input, Tooltip, Button, Table } from 'antd';
import RegisterKeyword from "./RegisterKeyword"

export default class AddKeyword extends React.Component {
    render() {
        return (
            <div>
                <div style={{ marginTop: '1%', marginBottom: '1%' }}>
                    <div style={{ fontSize: '1.5em', fontWeight: 600 }}>
                        Register a new keyword
                    </div>
                </div>
                <div style={{ backgroundColor: '#FBFBFB', border: "0.5px solid #E9E9E9", borderRadius: "2px", padding: "1%" }}>
                    <RegisterKeyword />
                </div>
            </div>
        )
    }
}
