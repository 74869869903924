import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Row,
  List,
  Icon,
  Input,
  Tooltip,
  Button,
  Tag,
  Checkbox,
  notification,
  Popover,
  Modal,
  Radio,
  Switch
} from "antd";
import "../index.css";
import store from "../config/store";
import { async } from "q";
import { Link } from "react-router-dom";
import { IntercomAPI } from "react-intercom";
import { observer } from "mobx-react";
import analytics from "../config/analytics";

const standard = [
  "Unlimited USSD Surveys.",
  "Unlimited Keyword Registration.",
  "Unlimited USSD Survey Responses.",
  "SMS Reminders For Incomplete Surveys.",
  "Data Visualisation of Responses."
];

const premium = [
  "All Standard Account Features.",
  "Customized SMS Sender ID.",
  "Customized USSD Completion Alerts.",
  "Customized SMS Completion Messages To Respondents.",
  "Customized Frequency For Your USSD Session (Once / Weekly / Monthly / Unlimited).",
  "Excel & CSV Survey data export."
];
class Pricing extends React.Component {
  state = {
    firstName: ""
  };

  componentWillMount = () => {
    analytics.track(analytics.events.PRICING_VIEWED);
  };

  handlePremiumClicked = () => {
    IntercomAPI(
      "showNewMessage",
      "Hello there! I would like to upgrade my account to a PREMIUM ACCOUNT. Please assist me on the next steps to do so. Thank you!"
    );
    IntercomAPI("trackEvent", "Premium - Clicked");
    analytics.track(analytics.events.PRICING_PREMIUM_CLICKED);
  };

  render() {
    return (
      <div>
        <div>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>Pricing</div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <p
                  style={{
                    backgroundColor: "#10336f",
                    color: "white",
                    padding: "35px 30px",
                    fontWeight: "bold"
                  }}
                >
                  <center>
                    <span style={{ fontSize: "1.5em" }}>Standard</span>
                    {!store.isPremium && (
                      <div>
                        <Tag color="#53AE61">Current Plan</Tag>{" "}
                      </div>
                    )}
                  </center>
                </p>
                <center>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5em",
                        color: "#10336f"
                      }}
                    >
                      GHS 0.15
                    </span>
                    <br /> per USSD Session
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5em",
                        color: "#10336f"
                      }}
                    >
                      GHS 0.08
                    </span>
                    <br /> per SMS Sent
                  </p>
                </center>
                <List
                  size="small"
                  bordered
                  dataSource={standard}
                  renderItem={item => <List.Item>{item}</List.Item>}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <p
                  style={{
                    backgroundColor: "#6a1547",
                    color: "white",
                    padding: "35px 30px",
                    fontWeight: "bold"
                  }}
                >
                  <center>
                    <span style={{ fontSize: "1.5em" }}>
                      Premium
                      {store.isPremium && (
                        <div>
                          <Tag color="#53AE61">Current Plan</Tag>{" "}
                        </div>
                      )}
                    </span>
                  </center>
                </p>
                <center>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5em",
                        color: "#6a1547"
                      }}
                    >
                      GHS 0.08
                    </span>
                    <br /> per USSD Session
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5em",
                        color: "#6a1547"
                      }}
                    >
                      GHS 0.048
                    </span>
                    <br /> per SMS Sent
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5em",
                        color: "#6a1547"
                      }}
                    >
                      GHS 100 / Month
                    </span>
                    <br /> You pay <b>GHS 1,000</b> if billed annually (12
                    Months) <br />You receive an additional <b>GHS 100 credits</b> for surveys and SMS Messages if billed annually
                  </p>
                </center>
                <center>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.handlePremiumClicked()}
                  >
                    UPGRADE MY ACCOUNT NOW
                  </Button>
                </center>
                <br />
                <List
                  size="small"
                  bordered
                  dataSource={premium}
                  renderItem={item => <List.Item>{item}</List.Item>}
                />
                <br />
                <center>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.handlePremiumClicked()}
                  >
                    UPGRADE MY ACCOUNT NOW
                  </Button>
                </center>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default observer(Pricing);
