import React, { Component } from "react";
import axios from "axios";

export default class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectURL: ""
    };
  }

  async componentDidMount() {
    const { code } = this.props.match.params;

    // Super simple referral link via Branch
    const url = `https://ussdsurveys.com/register?invite_code=${code}`;
    setTimeout(() => {
      window.location = url;
    }, 4000);
  }

  async componentWillMount() {
    const { code } = this.props.match.params;

    const response = await axios.get(`/referrals/referrer/${code}`);
    const account = response.data;

    this.setState({
      displayName: `${account.first_name} ${account.last_name}`
    });
  }

  render() {
    const { displayName } = this.state;

    return (
      <div className="posCenter" style={{ textAlign: "center" }}>
        <h1>Congratulations!</h1>
        <h2>Welcome to the Future of USSD Data Collection</h2>
        <br />

        {displayName && (
          <div>
            <h2 style={{ fontWeight: "bold" }}>{displayName}</h2>
            {displayName && (
              <h2>{`sent you an exclusive invite to USSD Surveys™`}</h2>
            )}
          </div>
        )}
      </div>
    );
  }
}
