import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Row,
  Icon,
  Input,
  Tooltip,
  Button,
  Checkbox,
  notification,
  Popover,
  Modal,
  Radio,
  Switch
} from "antd";
import "../index.css";
import store from "../config/store";
import { async } from "q";
import { Link } from "react-router-dom";
import { IntercomAPI } from "react-intercom";
import { observer } from "mobx-react";
import analytics from "../config/analytics";

const successNotificationWithIcon = type => {
  notification[type]({
    message: "Great!",
    description: `Survey created successfully.`
  });
};

class Account extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    currentPassword: "",
    newPasswordReset: "",
    confirmPasswordReset: "",
    loadingNameUpdate: true,
    loadingPasswordUpdate: true,
    checked: true
  };

  componentDidMount() {
    this.fetchUserDetails();
    analytics.track(analytics.events.ACCOUNT_VIEWED);
  }

  fetchUserDetails = async () => {
    await store.fetchCurrentUser();

    console.log(store.first);
    this.setState({
      firstName: store.first,
      lastName: store.last,
      phoneNumber: store.phone,
      email: store.email,
      loadingNameUpdate: false,
      loadingPasswordUpdate: false
    });
  };

  updateNames = () => {
    this.setState({ loadingNameUpdate: true });
    axios
      .post("accounts/update", {
        first: this.state.firstName,
        last: this.state.lastName
      })
      .then(response => {
        console.log("Response: ", response);
        alert("Account name updated successfully");
        this.setState({ loadingNameUpdate: false });
        analytics.track(analytics.events.ACCOUNT_UPDATE_NAME, {
          First: this.state.firstName,
          Last: this.state.lastName
        });
      })
      .catch(error => {
        console.log("Error: ", error);
        alert("Account name failed to update");
        this.setState({ loadingNameUpdate: false });
      });
  };

  resetPassword = () => {
    this.setState({ loadingPasswordUpdate: true });
    axios
      .post("accounts/change_password", {
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPasswordReset,
        newPasswordConfirmation: this.state.confirmPasswordReset
      })
      .then(response => {
        console.log("Response: ", response);
        alert("Account password updated successfully");
        this.setState({ loadingPasswordUpdate: false });
      })
      .catch(error => {
        console.log("Error: ", error);
        alert("Account password failed to rest");
        this.setState({ loadingPasswordUpdate: false });
      });
  };

  toggleSMSAlerts = e => {
    console.log("checked = ", e.target.checked);

    const enabled = e.target.checked;

    axios
      .post("accounts/sms_alerts", {
        enabled: e.target.checked
      })
      .then(response => {
        console.log("Response: ", response);
        analytics.track(analytics.events.ACCOUNT_TOGGLE_SMS_NOTIFICATION, {
          Enabled: enabled
        });
      })
      .catch(error => {
        console.log("Error: ", error);
      });

    this.setState({
      checked: e.target.checked
    });
  };

  handleSenderIdPopoverClicked = () => {
    IntercomAPI(
      "showNewMessage",
      "Hello there! I would like to change my sender id. Please assist me on the next steps to do so. Thank you!"
    );
  };

  render() {
    const label = "Send me SMS Messages when a survey is completed";
    return (
      <div>
        <div style={{ marginTop: "1%", marginBottom: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>My Account</div>
        </div>

        <div
          style={{
            backgroundColor: "#FBFBFB",
            border: "0.5px solid #E9E9E9",
            borderRadius: "2px",
            padding: "1%"
          }}
        >
          <h2>Account Controls</h2>
          <Checkbox
            checked={this.state.checked}
            onChange={this.toggleSMSAlerts}
          >
            {label}
          </Checkbox>
          <br />
          <br />
          <Row>
            <Col xs={18} sm={18} md={18} lg={18}>
              <span>SMS Sender ID (When an SMS is sent to your customers)</span>
              <Input disabled={true} value={store.senderId} />
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              {store.isPremium ? (
                <Popover
                  title="Please tap this blue button to contact us with the new sender ID you would like to use"
                  trigger="hover"
                  onClick={() => {
                    this.handleSenderIdPopoverClicked();
                  }}
                >
                  <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                    <center>
                      <Icon
                        style={{
                          backgroundColor: "white",
                          color: "blue",
                          fontSize: "2em"
                        }}
                        type="plus-circle"
                        theme="filled"
                      />
                      <br />
                      <span style={{ fontSize: "0.7em" }}>Request Change</span>
                    </center>
                  </div>
                </Popover>
              ) : (
                  <Popover
                    content={premium_sender_sms}
                    title="UPGRADE TO PREMIUM"
                    trigger="hover"
                    onClick={() => {
                      analytics.track(
                        analytics.events.ACCOUNT_UPGRADE_SENDER_ID_TAPPED
                      );
                    }}
                  >
                    <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                      <Link to="/pricing">
                        <center>
                          <Icon
                            style={{
                              backgroundColor: "white",
                              color: "green",
                              fontSize: "2em"
                            }}
                            type="plus-circle"
                            theme="filled"
                          />
                          <br />
                          <span style={{ fontSize: "0.7em" }}>CHANGE</span>
                        </center>
                      </Link>
                    </div>
                  </Popover>
                )}
            </Col>
          </Row>
          <br />
          <br />
          <hr />
          <br />
          <h2>Account details</h2>

          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <label for="firstname">First Name:</label>
              <Input
                name="firstname"
                value={this.state.firstName}
                onChange={event => {
                  this.setState({ firstName: event.target.value });
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <label for="lastname">Last Name:</label>
              <Input
                name="lastname"
                value={this.state.lastName}
                onChange={event => {
                  this.setState({ lastName: event.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <label for="phonenumber">Phone Number:</label>
              <Input
                name="phonenumber"
                value={this.state.phoneNumber}
                disabled
                onChange={event => {
                  this.setState({ phoneNumber: event.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <label for="Email">Email:</label>
              <Input
                name="email"
                value={this.state.email}
                disabled
                onChange={event => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                type="primary"
                loading={this.state.loadingNameUpdate}
                onClick={() => {
                  this.updateNames();
                }}
              >
                {" "}
                Update Account Name
              </Button>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <h2> Password Reset</h2>
          <p>
            Please enter a new password and confirmation to reset your old
            password to a new one.
          </p>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <label for="currentassword">Current Password</label>
              <Input.Password
                type="password"
                name="currentpassword"
                value={this.state.currentPassword}
                onChange={event => {
                  this.setState({ currentPassword: event.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <label for="password">New Password</label>
              <Input.Password
                type="password"
                name="password"
                value={this.state.newPasswordReset}
                onChange={event => {
                  this.setState({ newPasswordReset: event.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <label for="passwordreset">Confirm New Password:</label>
              <Input.Password
                type="password"
                name="passwordreset"
                value={this.state.confirmPasswordReset}
                onChange={event => {
                  this.setState({ confirmPasswordReset: event.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                type="primary"
                loading={this.state.loadingPasswordUpdate}
                onClick={() => {
                  this.resetPassword();
                }}
              >
                {" "}
                Reset Password
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const premium_sender_sms = (
  <div>
    <p>
      To change the name of the SMS sender when your customer receives a message
      from you, please upgrade to a premium account.
    </p>
    <Link to="/pricing">
      Click here to learn more and upgrade your account!
    </Link>
  </div>
);

export default observer(Account);
