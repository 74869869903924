import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import axios from "axios";

// Components
import App from "../App";
import Login from "../components/Login";
import Register from "../components/Register";

import Dashboard from "../components/Dashboard";
import Surveys from "../components/Surveys";
import Keywords from "../components/Keywords";
import Contacts from "../components/Contacts";
import AddKeyword from "../components/AddKeyword";
import AddSurvey from "../components/AddSurvey";
import EditSurvey from "../components/EditSurvey";
import Account from "../components/Account";
import Billing from "../components/Billing";
import Pricing from "../components/Pricing";
import Referral from "../components/Referral";
import Campaigns from "../components/Campaigns";
import AllAccounts from "../components/AllAccounts";
import Invite from "../components/Invite";
import ReferralTerms from "../components/ReferralTerms";
import PrivacyPolicy from "../components/PrivacyPolicy";
// import AdminProfile from "../components/AdminProfile";

function loggedIn() {
  // Why two??
  // axios.defaults.baseURL = "https://africa-texting.herokuapp.com/v1";
  // State of logged in depends on if there's a value in the local storage.
  const token = localStorage.getItem("accessToken");
  //   console.log("Token in routes.js: ", token);

  // Make sure the Authorization token is setup before routing anywhere
  axios.defaults.headers["Authorization"] = token;
  const result = token !== null;
  return result;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

const routes = (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route exact path="/invite/:code" component={Invite} />

      <App>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/new_survey" component={AddSurvey} />
          <PrivateRoute path="/edit_survey/:surveyId" component={EditSurvey} />
          <PrivateRoute path="/surveys" component={Surveys} />
          <PrivateRoute path="/keywords" component={Keywords} />
          <PrivateRoute path="/account" component={Account} />
          <PrivateRoute path="/new_keyword" component={AddKeyword} />
          <PrivateRoute path="/contacts" component={Contacts} />
          <PrivateRoute path="/billing" component={Billing} />
          <PrivateRoute path="/campaigns" component={Campaigns} />
          <PrivateRoute path="/referrals" component={Referral} />
          <PrivateRoute path="/pricing" component={Pricing} />
          <PrivateRoute path="/referralterms" component={ReferralTerms} />
          <PrivateRoute path="/privacypolicy" component={PrivacyPolicy} />
          <PrivateRoute path="/allaccounts" component={AllAccounts} />
        </Switch>
      </App>
    </Switch>
  </Router>
);

export default routes;
