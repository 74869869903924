import Amplitude from "amplitude-js";
const amplitude = Amplitude.getInstance();

amplitude.init("f908f36d3764e726de464616f80cb2f6");

const userProperties = {
  DISPLAY_NAME: "Display Name",
  IS_PREMIUM: "Is Premium",
  NUM_KEYWORDS: "Number of Keywords"
};

const events = {
  // Register
  REGISTER_VIEWED: "Register - Viewed",
  REGISTER_CLICKED: "Register - Clicked",
  REGISTER_SUCCESS: "Register - Success",
  REGISTER_FAILED: "Register - Failed",

  // Keyword
  KEYWORD_VIEWED: "Keyword - My Keywords Viewed",
  KEYWORD_REGISTER_VIEWED: "Keyword - Register New Viewed",
  KEYWORD_REGISTERD_CLICKED: "Keyword - Register Clicked",

  // Pricing
  PRICING_VIEWED: "Pricing - Viewed",
  PRICING_PREMIUM_CLICKED: "Pricing - Premium Clicked",

  // Dashboard
  DASHBOARD_VIEWED: "Dashboard - Viewed",

  // Survey Detail
  SURVEY_EXPORT: "Survey - Export",
  SURVEY_TOGGLE_ACTIVE: "Survey - Toggle Active",
  SURVEY_SELECTION_CHANGED: "Survey - Selection Changed",
  SURVEY_TOGGLE_INDIVIDUAL_RESPONSES: "Survey - Toggle Individual Responses",
  SURVEY_TOGGLE_SUMMARIES: "Survey - Toggle Summaries",

  // Create Survey
  CREATE_SURVEY_SELECT_KEYWORD: "Create Survey - Select Keyword",
  CREATE_SURVEY_TOGGLE_ACTIVE: "Create Survey - Toggle Active",
  CREATE_SURVEY_ADD_QUESTION_CLICKED: "Create Survey - Add Question Clicked",
  CREATE_SURVEY_MULTI_CHOICE_TOGGLED: "Create Survey - Multi Choice Toggled",
  CREATE_SURVEY_OPEN_TOGGLED: "Create Survey - Open Toggled",
  CREATE_SURVEY_QUESTION_SAVED: "Create Survey - Question Saved",
  CREATE_SURVEY_REORDERED:
    "Create Survey - Reordered [Which ones got re-ordered??]",
  CREATE_SURVEY_DELETED: "Create Survey - Question Deleted",
  CREATE_SURVEY_EDIT_CLICKED: "Create Survey - Question Edit Clicked",
  CREATE_SURVEY_QUESTION_MODAL_DISMISSED:
    "Create Survey - Question Modal Dismissed",
  CREATE_SURVEY_TOGGLE_FREQUENCY: "Create Survey - Toggle Frequency",
  CREATE_SURVEY_SUCCESS: "Create Survey - Success",
  CREATE_SURVEY_FAILED: "Create Survey - Failed",

  // Account
  ACCOUNT_VIEWED: "Account - Viewed",
  ACCOUNT_TOGGLE_SMS_NOTIFICATION: "Account - SMS Notification Toggled",
  ACCOUNT_UPGRADE_SENDER_ID_TAPPED: "Account - Upgrade Sender Id Tapped",
  ACCOUNT_UPDATE_NAME: "Account - Update Name",

  // Billing
  BILLING_VIEWED: "Billing - Viewed"
};

/** Set user properties */
const setUserProperty = (property, value) => {
  amplitude.setUserProperties({
    [property]: value
  });
};

/**
 * Track a specific event
 * @param { String } event String from events object above
 * @param { Object } properties - Event Properties
 */
const track = (event, properties) => {
  amplitude.logEvent(event, properties);
};

export default {
  events,
  userProperties,
  track,
  setUserProperty,
  amplitude
};
