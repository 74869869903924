import React from "react";
import {
  Button,
  Col,
  Row,
  Icon,
  Input,
  List,
  Card,
  Popover,
  Spin,
  Select,
  Switch,
  Modal,
  Table,
  notification,
  Avatar,
  Tabs
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Doughnut, Bar, Line, Pie } from "react-chartjs-2";
import { observer } from "mobx-react";
import { errorMessage } from "../util/helpers";
import store from "../config/store";
import EmptyBox from "../assets/images/emptybox.png";
import Warning from "../assets/images/warning.png";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import analytics from "../config/analytics";

// import '../assets/canvasjs.react';
var CanvasJSReact = require("../assets/canvasjs.react");
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Notification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message
  });
};

class Surveys extends React.Component {
  state = {
    visible: false,
    questions: [],
    summary: [],
    stats: [],
    engagement_count: null,
    complete_engagement: null,
    individual_responses: [],
    complete_rate: null,
    survey: [],
    rawResponses: [],
    questionTitleInModal: "",
    filteredInfo: null,
    sortedInfo: null,
    currentSurveyId: null,
    displayData: false,
    selectedTextword: null,
    isLoading: true,
    level: "LEVEL_ONE",
    levelCount: 10,
    isProcessing: true,
    title: "",
    exportableCSV: []
  };

  componentWillMount = async () => {
    var surveyID;
    console.log(this.props);
    if (this.props.location.state !== undefined) {
      surveyID = this.props.location.state.selectedSurvey;
    } else {
      surveyID = "";
    }

    this.setState({
      currentSurveyId: surveyID
    });

    await store.fetchAllSurveys();
    console.log(store.surveys.length);
    // Take the first value and put that in here
    if (this.state.currentSurveyId !== "") {
      this.surveySelectionChanged(this.state.currentSurveyId);
    } else if (store.surveys.length > 0) {
      const surveyId = store.surveys[0].id;

      this.surveySelectionChanged(surveyId);

      this.setState({
        currentSurveyId: surveyId
      });
    } else {
      this.setState({
        displayData: false,
        isLoading: false,
        selectedTextword: "your created keyword"
      });
    }

    if (this.state.level === "FREE" && this.state.levelCount > 10) {
      Modal.info({
        title: "Support Notice",
        content: (
          <div>
            <center>
              <img src={Warning} />
              <p>
                Unfortunately you have exceeded your limits for the free
                package.
                <br />
                <br />
                Reach out to us on <b>+233 24 306 5135‬</b> via Phone, SMS or
                WhatsApp to upgrade your plan.
              </p>
            </center>
          </div>
        ),
        onOk() {}
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    //get details
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState(
      {
        visible: false
      },
      () => {
        // Right after we hide this modal, clear the previous state
        this.setState({
          rawResponses: [],
          questionTitleInModal: ""
        });
      }
    );
  };

  onChange = async shouldActivate => {
    console.log(`switch to ${shouldActivate}`);

    const { currentSurveyId } = this.state;
    console.log("Check Survey; ", currentSurveyId);

    if (shouldActivate) {
      axios
        .post(`surveys/activate/${currentSurveyId}`)
        .then(response => {
          console.log("Activate Response: ", response);
          const survey = response.data.survey;
          Notification(
            "success",
            "Survey Activated",
            `${survey.title} is now active and can be taken by anyone!`
          );

          analytics.track(analytics.events.SURVEY_TOGGLE_ACTIVE, {
            "Survey Id": this.state.currentSurveyId,
            Success: true,
            Active: true
          });

          this.setState({
            is_active: true
          });

          store.fetchAllSurveys();
        })
        .catch(error => {
          console.log("Error: ", error);
          const message = errorMessage(error);
          Notification("error", "Error", message);
          analytics.track(analytics.events.SURVEY_TOGGLE_ACTIVE, {
            "Survey Id": this.state.currentSurveyId,
            Success: false,
            Active: false,
            Message: message
          });
        });
    } else {
      axios
        .post(`surveys/deactivate/${currentSurveyId}`)
        .then(response => {
          console.log("Deactivate Response: ", response);
          const survey = response.data.survey;
          Notification(
            "success",
            "Survey Deactivated",
            `${survey.title} is now turned off`
          );

          analytics.track(analytics.events.SURVEY_TOGGLE_ACTIVE, {
            "Survey Id": this.state.currentSurveyId,
            Success: true,
            Active: false
          });

          // Update the state
          this.setState({
            is_active: false
          });
          store.fetchAllSurveys();
        })
        .catch(error => {
          console.log("Error: ", error);
          const message = errorMessage(error);
          Notification("error", "Error", message);
          analytics.track(analytics.events.SURVEY_TOGGLE_ACTIVE, {
            "Survey Id": this.state.currentSurveyId,
            Success: false,
            Active: true,
            Message: message
          });
        });
    }
  };

  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  fetchAnalytics = surveyId => {
    axios
      .get(`surveys/analytics/${surveyId}`)
      .then(response => {
        this.setState({
          isLoading: false
        });
        console.log(response.data);

        const {
          questions,
          summary,
          stats,
          survey,
          individual_responses
        } = response.data.analysis;
        const engagement_count =
          stats.engagement_count > 0 ? stats.engagement_count : "-";
        const complete_engagement =
          stats.complete_engagement > 0 ? stats.complete_engagement : "-";
        const complete_rate = stats.complete_rate;

        if (stats.engagement_count === null) {
          this.setState({
            displayData: false
          });
        } else {
          this.setState({
            displayData: true
          });
        }

        var selectedTextword = survey.textword.word;

        const {
          id: currentSurveyId,
          title,
          description,
          created_at,
          updated_at,
          is_active,
          textword_id,
          submission_frequency
        } = survey;

        this.setState({
          questions,
          currentSurveyId,
          selectedTextword,
          summary,
          engagement_count,
          complete_engagement,
          complete_rate,
          title,
          description,
          created_at,
          updated_at,
          is_active,
          textword_id,
          individual_responses,
          submission_frequency
        });

        this.saveExportableCSV(questions, individual_responses);
      })
      .catch(error => {
        this.setState({
          isLoading: false
        });
        console.log(error);
      });
  };

  saveExportableCSV = (questions, responses) => {
    console.log("@@ Save export questions", questions);
    console.log("@@ Save export, respons", responses);

    let exportable = [];

    const questionTitles = questions.map(q => q.question);
    const headers = ["phone", ...questionTitles];
    exportable.push(headers);

    responses.forEach(r => {
      const answers = r.responses.map(a => a.response);
      const value = [r.phone, ...answers];
      exportable.push(value);
    });

    this.setState({
      exportableCSV: exportable
    });
  };

  surveySelectionChanged = surveyId => {
    this.setState({
      isLoading: true
    });

    console.log(surveyId);
    if (surveyId === null || surveyId === "") {
      this.setState({
        displayData: false
      });
    } else {
      this.setState({
        displayData: true
      });
    }

    this.setState(
      {
        surveyId
      },
      () => {
        this.fetchAnalytics(surveyId);
        analytics.track(analytics.events.SURVEY_SELECTION_CHANGED, {
          "Survey Id": surveyId
        });
      }
    );
  };

  onTabChange = key => {
    const surveyId = this.state.currentSurveyId;
    console.log("@@ Tab change: ", key);
    analytics.track(analytics.events.SURVEY_TOGGLE_SUMMARIES, {
      "Survey Id": surveyId
    });
    if (key === "summaries") {
    } else if (key === "individual_responses") {
      analytics.track(analytics.events.SURVEY_TOGGLE_INDIVIDUAL_RESPONSES, {
        "Survey Id": surveyId
      });
    }
  };

  viewDetails = id => {
    var levelCount = 0;
    this.setState({
      isProcessing: true
    });

    this.showModal();
    axios
      .get(`surveys/question/${id}`)
      .then(response => {
        var rawResponses = response.data.responses.raw;
        var respondu = [];
        const questionTitle = response.data.responses.summary.question;
        for (var i = 0; i < rawResponses.length; i++) {
          const obj = {
            phone: rawResponses[i].phone,
            response: rawResponses[i].response,
            created_at: moment(rawResponses[i].created_at).format(
              "MMMM DD YYYY @ HH:mm"
            )
          };
          respondu.push(obj);
        }

        this.setState({
          rawResponses: respondu,
          questionTitleInModal: questionTitle,
          isProcessing: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isProcessing: false
        });
      });
  };

  surveySelectionComponent = () => {
    const { title } = this.state;

    const surveys = store.surveys.map(survey => {
      const { title, id, is_active } = survey;
      const activeLabel = is_active ? "Active" : "Inactive";
      const activeColor = is_active ? "green" : "red";
      return (
        <Select.Option value={id} key={id}>
          {title}
          <p style={{ fontStyle: "italic", color: activeColor }}>
            {activeLabel}
          </p>
        </Select.Option>
      );
    });

    return (
      <div
        style={{
          backgroundColor: "#FBFBFB",
          border: "0.5px solid #E9E9E9",
          borderRadius: "2px",
          padding: "2%",
          boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
        }}
      >
        <center>
          <Select
            size={"large"}
            style={{ width: "70%" }}
            value={title}
            onChange={this.surveySelectionChanged}
          >
            {surveys}
          </Select>
        </center>
        <p style={{ fontWeight: 600, fontSize: "1.2em", textAlign: "center" }}>
          You currently have{" "}
          <span style={{ color: "blue" }}>{store.surveys.length}</span>{" "}
          survey(s)
        </p>
      </div>
    );
  };

  renderItemTypeLabel = type => {
    let color;
    switch (type) {
      case "multiple_choice":
        color = "#5221C7";
        type = "Multiple Choice";
        break;
      case "open_ended":
        color = "#216CC7";
        type = "Open Ended";
        break;
      case "numeric":
        color = "#216CC7";
        type = "Open Ended";
        break;
      default:
        break;
    }

    return (
      <span
        style={{
          color: color,
          fontWeight: 600,
          fontSize: "1.0em"
        }}
      >
        {type}
      </span>
    );
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  renderQuestionList = () => {
    const { questions, rawResponses, questionTitleInModal } = this.state;

    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...this.getColumnSearchProps("phone")
      },
      {
        title: "Response",
        dataIndex: "response",
        key: "response"
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created"
      }
    ];

    return (
      <div>
        <div>
          <Modal
            title={questionTitleInModal}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={"80%"}
            footer={[]}
          >
            {this.state.isProcessing ? (
              <center>
                <Spin />
              </center>
            ) : (
              <div>
                {this.state.level === "FREE" ? (
                  <div>
                    <Table
                      dataSource={rawResponses.splice(0, this.state.levelCount)}
                      columns={columns}
                      onChange={this.handleChange}
                    />
                    <table>
                      <tr key={"FREE"}>
                        <td>
                          {" "}
                          <i style={{ color: "#3E7DAD" }}>
                            {" "}
                            <Icon type="warning" theme="filled" /> Extra data
                            items available. Please upgrade your account to see
                            all responses. <br />
                            Reach out to us on <b>+233 24 306 5135‬</b> via
                            Phone, SMS or WhatsApp.
                          </i>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <div>
                    <Table
                      dataSource={rawResponses}
                      columns={columns}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </div>
            )}
          </Modal>
        </div>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <table style={{ width: "100%", borderRadius: "15px" }}>
                <thead>
                  {/* <td style={{ backgroundColor: '#FBFBFB' }}>
                      <div style={{ color: '#222', fontSize: '1.1em', fontWeight: 800, paddingLeft: '20px' }}>Survey Questions</div>
                    </td> */}
                  <tr style={{ backgroundColor: "#f0f0f0" }}>
                    <th>QUESTION</th>
                    <th>TYPE</th>
                    <th>RESPONSES</th>
                    <th>
                      <Icon type="eye" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((item, index) => (
                    <tr key={index}>
                      <td>{item.question}</td>
                      <td>{this.renderItemTypeLabel(item.type)}</td>
                      <td>
                        {item.responses_count > 0 ? item.responses_count : "-"}
                      </td>
                      <td>
                        <Button
                          onClick={() => this.viewDetails(item.id)}
                          type="link"
                        >
                          View Responses
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  renderSummary = () => {
    const { summary } = this.state;

    return summary.map((item, index) => {
      const { type } = item;
      if (type === "numeric") {
        return this.renderSummaryNumeric(item);
      } else if (type === "multiple_choice") {
        return this.renderSummaryMultipleChoice(item);
      } else if (type === "open_ended") {
        return this.renderSummaryOpenEnded(item);
      }
    });
  };

  renderIndividualResponses = () => {
    const { individual_responses } = this.state;

    let limitResponses = false;

    if (individual_responses.length > 4 && !store.isPremium) {
      limitResponses = true;
    }

    return (
      <div>
        <List
          dataSource={
            store.isPremium
              ? individual_responses
              : individual_responses.slice(0, 5)
          }
          renderItem={item => (
            <List.Item style={{ backgroundColor: "white", padding: 10 }}>
              <div>
                <h3>
                  {item.phone} -{" "}
                  <i style={{ fontSize: "0.8em" }}>
                    {moment(item.responses[0].created_at).format(
                      "MMMM DD YYYY @ HH:mm"
                    )}
                  </i>
                </h3>
                {item.responses.map((item, index) => (
                  <div key={index}>
                    <p>Q. {item.question}</p>
                    <p>
                      <b>A. {item.response}</b>
                    </p>
                  </div>
                ))}
              </div>
            </List.Item>
          )}
        />

        {!limitResponses ? (
          <div>
            <center>
              <b> You are up to date 🎉</b>
            </center>
          </div>
        ) : (
          <Popover
            content={
              <div>
                <p>
                  {" "}
                  You have received {individual_responses.length} responses. To
                  see all {individual_responses.length} responses, you would
                  need a premium account.
                </p>
                <Link to="/pricing">
                  Click here to learn more and upgrade your account!
                </Link>
              </div>
            }
            title="UPGRADE TO PREMIUM"
            trigger="hover"
          >
            <div style={{ marginTop: "20px", marginLeft: "10px" }}>
              <Link to="/pricing">
                <center>
                  <Icon
                    style={{
                      backgroundColor: "white",
                      color: "green",
                      fontSize: "2em"
                    }}
                    type="plus-circle"
                    theme="filled"
                  />
                  <br />
                  <span style={{ fontSize: "1.2em", fontWeight: 400 }}>
                    SEE ALL ({individual_responses.length}) RESPONSES
                  </span>
                </center>
              </Link>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  renderSummaryMultipleChoice = item => {
    const { id, question, responses } = item;
    let choices = [];
    let answers = [];
    for (const response of responses) {
      choices.push(response.response);
      answers.push(response.count);
    }

    const data = {
      labels: choices,
      datasets: [
        {
          label: "# of Selections",
          data: answers,
          backgroundColor: "rgba(54, 162, 235, 1)",
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 0.5
        }
      ]
    };

    if (this.state.level === "FREE" && this.state.levelCount > 10) {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {question}
              </div>
              <center>
                <div
                  style={{ padding: "5%", fontWeight: 600, fontSize: "1.2em" }}
                >
                  {" "}
                  <i style={{ color: "#3E7DAD" }}>
                    {" "}
                    <Icon type="warning" theme="filled" /> Please upgrade your
                    account to see charts of further results. <br />
                    Reach out to us on <b> +233 24 306 5135‬</b> via Phone, SMS
                    or WhatsApp.
                  </i>{" "}
                </div>
              </center>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {question}
              </div>
              <center>
                <Bar
                  data={data}
                  width={8}
                  height={200}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                              if (value % 1 === 0) {
                                return value;
                              }
                            }
                          }
                        }
                      ]
                    }
                  }}
                />
              </center>
            </div>
          </Col>
        </Row>
      );
    }
  };

  renderSummaryNumeric = item => {
    const { id, responses } = item;
    console.log(item);

    if (this.state.level === "FREE" && this.state.levelCount > 10) {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {item.question}
              </div>
              <table style={{ width: "100%", borderRadius: "15px" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f0f0f0" }}>
                    <th>Response</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {responses
                    .slice(0, this.state.levelCount - 5)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>{item.response}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>
                      {" "}
                      <i style={{ color: "#3E7DAD" }}>
                        {" "}
                        <Icon type="warning" theme="filled" /> Extra data items
                        & charts available. Please upgrade your account to see
                        all responses. <br />
                        Reach out to us on <b>+233 24 306 5135‬</b> via Phone,
                        SMS or WhatsApp.
                      </i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {item.question}
              </div>
              <table style={{ width: "100%", borderRadius: "15px" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f0f0f0" }}>
                    <th>Response</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {responses.map((item, index) => (
                    <tr key={index}>
                      <td>{item.response}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    }
  };

  showExportNotification = () => {
    Notification(
      "success",
      "Export Began",
      `${this.state.title} data will export soon...`
    );
  };

  showNeedPremiumNotification = () => {
    Notification(
      "info",
      "Premium Account Needed",
      "Please upgrade to a Premium account in order to export 🙏🏾"
    );
  };

  renderSummaryOpenEnded = item => {
    const { id, responses } = item;
    var levelCount = 10;

    if (this.state.level === "FREE" && this.state.levelCount > 10) {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {item.question}
              </div>
              <table style={{ width: "100%", borderRadius: "15px" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f0f0f0" }}>
                    <th>Response</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {responses.slice(0, levelCount).map((item, index) => (
                    <tr key={index}>
                      <td>{item.response}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {" "}
                      <i style={{ color: "#3E7DAD" }}>
                        {" "}
                        <Icon type="warning" theme="filled" /> Extra data items
                        & charts available. Please upgrade your account to see
                        all responses. <br />
                        Reach out to us on <b> +233 24 306 5135‬</b> via Phone,
                        SMS or WhatsApp.
                      </i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={id}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div
              style={{
                backgroundColor: "#FBFBFB",
                border: "0.5px solid #E9E9E9",
                borderRadius: "15px",
                padding: "0%",
                marginBottom: "3%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid #ddd"
                }}
              >
                {item.question}
              </div>
              <table style={{ width: "100%", borderRadius: "15px" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f0f0f0" }}>
                    <th>Response</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {responses.map((item, index) => (
                    <tr key={index}>
                      <td>{item.response}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    }
  };

  sendSMSReminders = () => {
    const surveyId = this.state.currentSurveyId;
    const keyword = this.state.selectedTextword;
    var checkToggle = window.confirm(
      `This will send an SMS message to everyone who begun to take this survey but did not complete.\n\nMessage content: "Hi! We noticed you did not complete the survey for the keyword: ${keyword} Please dial *718*788# and enter ${keyword} to complete this survey!"\n\nProceed?`
    );
    if (checkToggle) {
      axios
        .post(`surveys/reminders/send/${surveyId}`)
        .then(response => {
          const length = response.data.reminders.length;
          const message = length > 0 ? "Messages" : "Message";
          Notification(
            "success",
            "Success",
            `${length} SMS ${message} sent successfully`
          );
        })
        .catch(error => {
          console.log("Error: ", error);
          Notification("error", "Error", "Error while sending SMS. " + error);
        });
    } else {
      // Do nothing
    }
  };

  render() {
    return (
      <div style={{ margin: "0% 5%" }}>
        <div style={{ marginTop: "1%", marginBottom: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>
            My Surveys
            <Link to="/new_survey">
              <Button
                style={{ fontSize: "0.8em", fontWeight: 600, marginLeft: "2%" }}
                type="primary"
              >
                <Icon type="plus-circle" theme="filled" />
                New Survey
              </Button>
            </Link>
          </div>
        </div>

        {this.surveySelectionComponent()}

        {this.state.isLoading ? (
          <div>
            <center>
              <br />
              <Spin />
            </center>
          </div>
        ) : (
          <div>
            <div
              style={{
                marginTop: "2%",
                marginBottom: "2%",
                boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
              }}
            >
              <center>
                <Card>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div style={{ margin: "8% 5%" }}>
                      <span
                        style={{
                          color: "#0D3990",
                          fontSize: "1.9em",
                          fontWeight: 600
                        }}
                      >
                        {this.state.engagement_count}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Engagement Count
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div style={{ margin: "8% 5%" }}>
                      <span
                        style={{
                          color: "#0D3990",
                          fontSize: "1.9em",
                          fontWeight: 600
                        }}
                      >
                        {this.state.complete_engagement}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Complete Engagement
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div style={{ margin: "8% 5%" }}>
                      <span
                        style={{
                          color: "#0D3990",
                          fontSize: "1.9em",
                          fontWeight: 600
                        }}
                      >
                        {(this.state.complete_rate * 100).toFixed(2)}%
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Completion Rate
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div style={{ margin: "8% 5%" }}>
                      <Switch
                        checked={this.state.is_active}
                        onChange={this.onChange}
                      />
                      <br />
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Survey Active
                      </span>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div style={{ margin: "8% 5%" }}>
                      <span
                        style={{
                          color: "#0D3990",
                          fontSize: "1.9em",
                          fontWeight: 600
                        }}
                      >
                        {capitalize(this.state.submission_frequency)}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Frequency
                      </span>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6}>
                    <Row>
                      <Col>
                        <div style={{ margin: "8% 5%" }}>
                          <span
                            style={{
                              color: "#0D3990",
                              fontSize: "1.9em",
                              fontWeight: 600
                            }}
                          >
                            <CSVLink
                              data={this.state.exportableCSV}
                              filename={`${this.state.title.trim()}.csv`}
                              onClick={() => {
                                analytics.track(
                                  analytics.events.SURVEY_EXPORT,
                                  {
                                    "Is Premium": store.isPremium,
                                    "Survey Id": this.state.currentSurveyId
                                  }
                                );
                                if (store.isPremium) {
                                  this.showExportNotification();
                                  return true;
                                } else {
                                  this.showNeedPremiumNotification();
                                  return false;
                                }
                              }}
                            >
                              Export Data
                            </CSVLink>
                          </span>

                          <br />
                          <span
                            style={{
                              color: "#909090",
                              fontSize: "1.0em",
                              fontWeight: 600
                            }}
                          >
                            Click to Export
                          </span>
                        </div>
                      </Col>
                      {!store.isPremium && (
                        <Col>
                          <Popover
                            content={
                              <div>
                                {" "}
                                <p>
                                  To export your responses into excel or a csv
                                  sheet, please upgrade your account.
                                </p>
                                <Link to="/pricing">
                                  Click here to learn more and upgrade your
                                  account!
                                </Link>
                              </div>
                            }
                            title="UPGRADE TO PREMIUM"
                            trigger="hover"
                          >
                            <Link to="/pricing">
                              <div style={{ marginTop: "20px" }}>
                                <center>
                                  <Icon
                                    style={{
                                      backgroundColor: "white",
                                      color: "green",
                                      fontSize: "2em"
                                    }}
                                    type="plus-circle"
                                    theme="filled"
                                  />
                                </center>
                              </div>
                            </Link>
                          </Popover>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div
                      style={{ margin: "8% 5%", cursor: "pointer" }}
                      onClick={() => {
                        this.sendSMSReminders();
                      }}
                    >
                      <span
                        style={{
                          color: "#438EF7",
                          fontSize: "1.9em",
                          fontWeight: 600
                        }}
                      >
                        Send Reminders
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#909090",
                          fontSize: "1.0em",
                          fontWeight: 600
                        }}
                      >
                        Click to send SMS to everyone who did not complete this
                        survey
                      </span>
                    </div>
                  </Col>
                </Card>
              </center>
            </div>
            <div>
              {this.state.displayData ? (
                <div>
                  <div style={{ backgroundColor: "white", padding: 30 }}>
                    <center>
                      <p style={{ fontSize: "1.5em" }}>
                        Dial <b>*718*788#</b> and enter{" "}
                        <b>{this.state.selectedTextword}</b> to take this survey
                      </p>
                    </center>

                    <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
                      <Tabs.TabPane tab="Survey Summaries" key="summaries">
                        {this.renderQuestionList()}
                        <h2>Response Breakdowns </h2>
                        {this.renderSummary()}
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab="Individual Responses"
                        key="individual_responses"
                      >
                        {this.renderIndividualResponses()}
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                </div>
              ) : (
                <div>
                  <Card style={{ boxShadow: "0.5px 0.5px 4px 0.5px #BABABA" }}>
                    <center>
                      <h2> Looks like you have no responses</h2>
                      <br />
                      <img size={128} src={EmptyBox} />
                      <br />
                      <label style={{ fontSize: "1.2em" }}>
                        Please create or select another survey in the survey
                        selection. Let people know they can take your survey via
                        the dashboard share tool{" "}
                        <Icon style={{ color: "#3F0404" }} type="share-alt" />
                        <br /> Alternatively, you can ask people to dial{" "}
                        <b>*718*788#</b> and enter{" "}
                        <b>{this.state.selectedTextword}</b> to take this survey
                      </label>
                    </center>
                  </Card>
                  <br />
                  {this.renderQuestionList()}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function capitalize(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export default observer(Surveys);

// this Questions by Type Not needed now

/*          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div
                style={{
                  backgroundColor: "#FBFBFB",
                  border: "0.5px solid #E9E9E9",
                  borderRadius: "15px",
                  marginRight: "1%",
                  marginBottom: "2%",
                  boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    backgroundColor: "#f0f0f0",
                    padding: "15px",
                    borderRadius: "15px 15px 0px 0px",
                    borderBottom: "1px solid #ddd"
                  }}
                >
                  Questions By Type
                </div>
                {
                  <table>
                    <tr>
                      <th>QUESTION</th>
                      <th>TYPE</th>
                      <th>RESPONSES</th>
                      <th>
                        <Icon type="eye" />
                      </th>
                    </tr>
                    {this.state.summary.map((item, index) => (
                      <tr key={index}>
                        <td>{item.question}</td>
                        <td>
                          {item.type == "multiple_choice"
                            ? "multiple choice"
                            : "open ended"}
                        </td>
                        <td>{item.responses.length}</td>
                        <td>
                          <span>View Responses</span>
                        </td>
                      </tr>
                    ))}
                  </table>
                }
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div
                style={{
                  backgroundColor: "#FBFBFB",
                  border: "0.5px solid #E9E9E9",
                  borderRadius: "15px",
                  marginLeft: "1%",
                  paddingBottom: "3%",
                  marginBottom: "6%",
                  boxShadow: "0.5px 0.5px 4px 0.5px #BABABA"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    backgroundColor: "#f0f0f0",
                    padding: "15px",
                    borderRadius: "15px 15px 0px 0px",
                    borderBottom: "1px solid #ddd"
                  }}
                >
                  Questions By Type
                </div>
                <center>
                  <Doughnut data={piedata} />
                </center>
              </div>
            </Col>
          </Row>
          */
