import React from "react";
import { Card, Col, Row, Icon, Input, Tooltip, Button, Collapse, Select } from 'antd';
import CreateSurvey from "./CreateSurvey"

export default class AddSurvey extends React.Component {
    render() {
        return (
            <div>
                <div style={{ marginTop: '1%', marginBottom: '1%' }}>
                    <div style={{ fontSize: '1.5em', fontWeight: 600 }}>
                        Create a new survey
                    </div>
                </div>
                <CreateSurvey />
            </div >
        )
    }
}
