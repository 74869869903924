import React from "react";
import CreateSurvey from "./CreateSurvey";

export default class AddSurvey extends React.Component {
  render() {
    const surveyId = this.props.match.params.surveyId;

    return (
      <div>
        <div style={{ marginTop: "1%", marginBottom: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>
            Edit your survey
          </div>
        </div>
        <CreateSurvey editSurveyId={surveyId} />
      </div>
    );
  }
}
