import React from "react";
import axios from "axios";
import "../index.css";
import store from "../config/store";
import { observer } from "mobx-react";
import analytics from "../config/analytics";
import { Link } from "react-router-dom";
import { Card, Col, Row, Icon, Input, Tooltip, Button, Collapse, notification, Select, Modal, Radio, Switch, Avatar, Tag, Popover } from 'antd';


const Notification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message
  });
};

class Referral extends React.Component {
  state = {
    loading: false,
    surveys: [],
    smsMessage: '',
    uniqueRespondents: 0
  };

  componentWillMount = () => {
    analytics.track(analytics.events.SMS_CAMPAIGN_VIEWED);
    var surveys = store.surveys;
    this.setState({
      surveys
    })
  };

  getUniqueRespondents = async (id) => {
    const response = await axios.get("/surveys/respondents/" + id);
    console.log(response.data.phones)
    this.setState({
      selectedSurveyId: id,
      uniqueRespondents: response.data.phones.length
    })
  }

  sendCampaign = () => {
    this.setState({ loading: true })
    var toSend = window.confirm('Send SMS to ' + this.state.uniqueRespondents + ' respondents?');
    if (toSend) {
      try {
        axios.post("/surveys/campaign", {
          surveyId: this.state.selectedSurveyId,
          message: this.state.smsMessage,
        });
      } catch (error) {
        Notification("error", "Error", "SMS Message failed to send.");
      }
      Notification("success", "Success", "SMS Message sent successfully.");
      this.setState({ smsMessage: '', loading: false })
    } else {
      //doNothing
    }

  }

  render() {
    var { uniqueRespondents } = this.state
    return (
      <div>
        <div>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>SMS Campaign</div>
          <Card>
            <div style={{ marginBottom: '10px', fontSize: "1.5em" }}>
              Send an SMS Message to everyone that has responded or engaged with any of your surveys <br />
              <b>{store.senderId}</b> will be used as the sender identification.
            </div>
            <div style={{ marginBottom: '1.5%' }}>
              <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Select Survey:</span>
              <Select style={{ width: '100%' }} onChange={(value) => this.getUniqueRespondents(value)}>
                {
                  store.surveys.map(survey => (
                    <Select.Option key={survey.id} value={survey.id} name={survey.title}>
                      {survey.title}
                    </Select.Option>
                  ))
                }
              </Select>
              <p>Your SMS will send to: <b>{(uniqueRespondents == 0 || uniqueRespondents == null) ? 'No' : uniqueRespondents} respondents</b></p>
            </div>
            <div style={{ marginBottom: '1.5%' }}>
              <span style={{ fontWeight: 200, fontSize: '1.1em' }}> Enter the SMS Message:</span>
              <Input placeholder="" value={this.state.smsMessage} onChange={(event) => this.setState({ smsMessage: event.target.value })} />
            </div>
            {store.isPremium ?
              (<Button type="primary" size={'large'} icon="save" loading={this.state.loading} onClick={() => this.sendCampaign()}>
                Send SMS Message
            </Button>) :
              (<Button type="primary" disabled size={'large'} icon="save" loading={this.state.loading} onClick={() => this.sendCampaign()}>
                Send SMS Message
            </Button>)
            }

            {store.isPremium ? (
              <span></span>
            ) : (
                <Popover
                  content={premium_sender_sms}
                  title="UPGRADE TO PREMIUM"
                  trigger="hover"
                  onClick={() => {
                    analytics.track(
                      analytics.events.ACCOUNT_UPGRADE_CAMPAIGN_SMS_TAPPED
                    );
                  }}
                >
                  <Link to="/pricing">
                    <span style={{ marginLeft: '10px' }}>
                      <Icon
                        style={{
                          backgroundColor: "white",
                          color: "green",
                          fontSize: "2em"
                        }}
                        type="plus-circle"
                        theme="filled"
                        label="test"
                      />
                      <b style={{ fontSize: "0.7em", marginLeft: '5px', }}>CHANGE</b>
                    </span>
                  </Link>
                </Popover>
              )}



          </Card>
        </div>
      </div>
    );
  }
}

const premium_sender_sms = (
  <div>
    <p>
      To send an SMS to the respondents of this survey, and setup your own unique sender ID,<br /> please upgrade to a premium account.
    </p>
    <Link to="/pricing">
      Click here to learn more and upgrade your account!
    </Link>
  </div>
);

export default observer(Referral);
