import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Popover,
  Icon,
  Input,
  Tag,
  Button,
  Table,
  Spin
} from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import moment from "moment";
import { handleError } from "../util/helpers";
import analytics from "../config/analytics";

export default class Billing extends React.Component {
  state = {
    items: [],
    balance: 0,
    isProcessing: true
  };

  componentDidMount() {
    this.fetchAccountBilling();
    analytics.track(analytics.events.BILLING_VIEWED);
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  fetchAccountBilling = async () => {
    try {
      const response = await axios.get("/accounts/billing_statement");
      console.log("@@ reposne: ", response.data);

      const { items, balance } = response.data;
      console.log(items);
      this.setState({ items, balance, isProcessing: false });
    } catch (error) {
      handleError(error);
    }
  };

  render() {
    const columns = [
      {
        title: "Timestamp",
        dataIndex: "created_at",
        key: "created_at",
        render: text => <span>{moment(text).format("LLL")}</span>
      },
      {
        title: "Note",
        dataIndex: "note",
        key: "note",
        ...this.getColumnSearchProps("note"),

        render: (note, item) => {
          let content;
          if (item.session_survey_id) {
            content = `For Survey: ${item.survey_title}`;
          } else if (item.sms_message) {
            content = item.sms_message;
          }
          return (
            <Popover title={note} content={content}>
              <span>{note}</span>
            </Popover>
          );
        }
      },
      {
        title: "Debit",
        dataIndex: "amount",
        key: "debit",
        ...this.getColumnSearchProps("amount"),
        render: amount => {
          return amount <= 0 && <Tag color="red">{`GHS ${amount} `}</Tag>;
        }
      },
      {
        title: "Credit",
        dataIndex: "amount",
        key: "credit",
        ...this.getColumnSearchProps("amount"),
        render: amount => {
          return amount > 0 && <Tag color="green">{`GHS ${amount} `}</Tag>;
        }
      }
    ];

    const { items } = this.state;

    return (
      <div>
        <div style={{ marginTop: "1%", marginBottom: "1%" }}>
          <div style={{ fontSize: "1.5em", fontWeight: 600 }}>
            My Billing History
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#FBFBFB",
            border: "0.5px solid #E9E9E9",
            borderRadius: "2px",
            padding: "1%"
          }}
        >
          {this.state.isProcessing ? (
            <center>
              <Spin />
            </center>
          ) : (
            <Table columns={columns} dataSource={items} />
          )}
        </div>
      </div>
    );
  }
}
